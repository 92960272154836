import React, { useState } from "react";
import { Grid2 as Grid, Typography, ListItem, ListItemText, TextField, useTheme } from "@mui/material";
import JobErCard from "components/JobErCard";
import { asyncRicercaComuni } from "utils/utilsRicerca";
import { salvaSede, disattivaSede, modificaSede } from "pages/portale_aziende/utils/utilsSede";
import JobErButton from "components/JobErButton";
import Swal from "sweetalert2";
import JobErAutocomplete from "components/JobErAutocomplete";

function GestioneSede(props) {
  const theme = useTheme();
  const [showForm, setShowForm] = useState(false);
  const [labelBottone, setLabelBottone] = useState("Inserisci una sede");
  const [titoloForm, setTitoloForm] = useState("Inserisci una nuova sede");
  const [loading, setLoading] = useState(false);
  const [optionComuni, setOptionComuni] = useState([]);

  const modificaForm = (sede) => {
    let copySede = sede;
    copySede.dataMod = new Date();
    copySede.userMod = props.authUser ? props.authUser.email : null;

    props.setNuovaSede(copySede);
    setShowForm(true);
    setLabelBottone("Chiudi");
    setTitoloForm("Modifica la sede di " + sede.comune);
  };

  return (
    <JobErCard
      title="Sedi"
      child={
        <Grid container>
          {props.sedi && props.sedi.length > 0 ? (
            <>
              {/* print sedi */}
              {props.sedi.map((obj, index) => (
                <ListItem alignItems="flex-start" key={obj.uuid}>
                  <ListItemText
                    key={obj.uuid + "_text"}
                    sx={{ marginLeft: 2 }}
                    primary={obj.descrizioneComune.toUpperCase()}
                    primaryTypographyProps={{
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    }}
                    secondary={
                      <Grid container>
                        <Grid size={12}>
                          <Typography
                            component="p"
                            sx={{
                              fontSize: "14px!important",
                              color: theme.palette.primary.main,
                            }}
                          >
                            {"Indirizzo: " + obj.indirizzo}
                          </Typography>
                        </Grid>
                        <Grid size={12} pt={1}>
                          <JobErButton
                            onClick={() => {
                              modificaForm(obj);
                            }}
                            color="warning"
                          >
                            {"Modifica"}
                          </JobErButton>
                          <JobErButton
                            onClick={() => {
                              Swal.fire({
                                text: "Sei sicuro di voler eliminare questa sede?",
                                icon: "warning",
                                showCancelButton: true,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  disattivaSede(obj.uuid, props.sedi, props.setSedi, props.authUser);
                                } else if (result.isDenied) {
                                }
                              });
                            }}
                            color="error"
                          >
                            {"Disattiva"}
                          </JobErButton>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              ))}
            </>
          ) : (
            !showForm && (
              <Grid size={12} mt={2} sx={{ minHeight: "100px" }} alignSelf="center">
                <Grid size={12} textAlign="center">
                  <Typography fontSize="14px" fontWeight="bold" sx={{ color: theme.palette.primary.main }}>
                    Nessuna sede
                  </Typography>
                </Grid>
              </Grid>
            )
          )}

          {showForm ? (
            <Grid size={12} mt={2} sx={{ minHeight: "100px" }} alignSelf="center">
              <Grid container spacing={2}>
                <Grid size={12} textAlign="center" pb={1}>
                  <Typography component="h5" fontWeight="bold" sx={{ color: theme.palette.primary.main }}>
                    {titoloForm}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                  <JobErAutocomplete
                    onClose={() => {
                      setOptionComuni([]);
                    }}
                    loading={loading}
                    size="small"
                    label="Comune Residenza"
                    value={
                      props.nuovaSede && props.nuovaSede.descrizioneComune && props.nuovaSede.comune
                        ? {
                            value: props.nuovaSede.comune,
                            label: props.nuovaSede.descrizioneComune,
                          }
                        : null
                    }
                    onInput={(e) => {
                      let inputValue = e.target.value;

                      if (inputValue?.length >= 3) {
                        setLoading(true);
                        asyncRicercaComuni(inputValue).then((response) => {
                          if (response) {
                            setOptionComuni(response);
                            setLoading(false);
                          }
                        });
                      }
                    }}
                    options={optionComuni}
                    onChange={(data, newValue) => {
                      props.setNuovaSede({ ...props.nuovaSede, comune: newValue.value, descrizioneComune: newValue.label });
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                  <TextField
                    fullWidth
                    label="Indirizzo e numero civico"
                    size="small"
                    value={props.nuovaSede ? props.nuovaSede.indirizzo : ""}
                    onChange={(e) => props.setNuovaSede({ ...props.nuovaSede, indirizzo: e.target.value })}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          <Grid size={12} textAlign="center" p={2}>
            <JobErButton
              onClick={() => {
                if (showForm) {
                  setShowForm(false);
                  setLabelBottone("Inserisci una sede");
                  props.setNuovaSede({});
                  setTitoloForm("Inserisci una nuova sede");
                } else {
                  setTitoloForm("Inserisci una nuova sede");
                  setLabelBottone("Chiudi");
                  setShowForm(true);
                }
              }}
            >
              {labelBottone}
            </JobErButton>
            {labelBottone === "Chiudi" && (
              <JobErButton
                onClick={() => {
                  if (titoloForm === "Inserisci una nuova sede") {
                    salvaSede(props.nuovaSede, props.authUser, props.sedi, props.setSedi, setShowForm, setLabelBottone, props.setNuovaSede, setTitoloForm);
                  } else {
                    modificaSede(props.nuovaSede, props.sedi, props.setSedi, setShowForm, setLabelBottone, props.setNuovaSede, setTitoloForm);
                  }
                }}
                color="success"
              >
                {"Salva"}
              </JobErButton>
            )}
          </Grid>
        </Grid>
      }
    />
  );
}
export default GestioneSede;
