import React from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function JobErAutocomplete({ error = false, label, focused, placeholder, ...props }) {
  return (
    <Autocomplete
      {...props}
      renderInput={(params) => <TextField {...props} {...params} label={label} placeholder={placeholder} focused={focused} error={error} />}
    />
  );
}
