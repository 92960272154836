import React from "react";
import JobErCard from "components/JobErCard";
import { Grid2 as Grid, Divider, TextField } from "@mui/material";
import options from "../../json/option_configuration.json";
import JobErButton from "components/JobErButton";
import Swal from "sweetalert2";
import JobErAutocomplete from "components/JobErAutocomplete";

function CardProfiloTitoliStudio(props) {
  const alertDeleteTitoloStudio = (uuid) => {
    Swal.fire({
      text: "Sei sicuro di voler cancellare questo interesse?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.onRemoveNestedData(uuid);
      } else if (result.isDenied) {
      }
    });
  };
  return (
    <JobErCard
      title="Titoli studio"
      child={
        <Grid container justifyContent="center" spacing={2} mt={1}>
          {props.dati.map((titoloStudio, index) => (
            <React.Fragment key={index}>
              <Grid size={{ xs: 12, md: 6 }}>
                <JobErAutocomplete
                  size="small"
                  label="Titolo di studio"
                  options={options.titoli_studio}
                  value={
                    titoloStudio.titoloStudio ? options.titoli_studio.find((opt) => opt.label.toLowerCase() === titoloStudio.titoloStudio.toLowerCase()) : null
                  }
                  onChange={(event, newValue) => {
                    props.onSetNestedDati(titoloStudio.uuid, "titoloStudio", newValue ? newValue.label : null);
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <JobErAutocomplete
                  size="small"
                  label="Corso frequentato"
                  value={
                    titoloStudio?.corsoFrequentato
                      ? options.corso_frequentato.find((opt) => opt.label.toLowerCase() === titoloStudio.corsoFrequentato.toLowerCase())
                      : null
                  }
                  options={options.corso_frequentato}
                  onChange={(event, newValue) => {
                    props.onSetNestedDati(titoloStudio.uuid, "corsoFrequentato", newValue ? newValue.label : null);
                  }}
                />
              </Grid>
              <Grid size={12}>
                <JobErAutocomplete
                  size="small"
                  label="In corso ?"
                  value={titoloStudio?.studiInCorso ? options.yes_no.find((opt) => opt.label.toLowerCase() === titoloStudio.studiInCorso.toLowerCase()) : null}
                  options={options.yes_no}
                  onChange={(event, newValue) => {
                    props.onSetNestedDati(titoloStudio.uuid, "studiInCorso", newValue ? newValue.label : null);
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label="Data inizio"
                  type="date"
                  size="small"
                  value={titoloStudio?.dataInizioCorso ? titoloStudio.dataInizioCorso : " "}
                  onChange={(e) => props.onSetNestedDati(titoloStudio.uuid, "dataInizioCorso", e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label="Data fine"
                  type="date"
                  size="small"
                  value={titoloStudio?.dataFineCorso ? titoloStudio.dataFineCorso : " "}
                  onChange={(e) => props.onSetNestedDati(titoloStudio.uuid, "dataFineCorso", e.target.value)}
                />
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  label="Istituto formazione"
                  size="small"
                  value={titoloStudio?.istitutoFormazione || ""}
                  onChange={(e) => props.onSetNestedDati(titoloStudio.uuid, "istitutoFormazione", e.target.value)}
                />
              </Grid>

              {index > 0 && (
                <Grid size={12}>
                  <JobErButton size="small" color="error" onClick={() => alertDeleteTitoloStudio(titoloStudio.uuid)}>
                    Rimuovi
                  </JobErButton>
                </Grid>
              )}
              <Grid size={12} m={1.5}>
                <Divider />
              </Grid>
            </React.Fragment>
          ))}
          <Grid size={12} mt={2}>
            <Grid container justifyContent="center">
              <Grid size="auto">
                <JobErButton size="small" color="success" onClick={() => props.onSave()}>
                  Salva
                </JobErButton>
                <JobErButton size="small" onClick={() => props.onAddNestedObject()}>
                  Aggiungi titolo di studio
                </JobErButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloTitoliStudio;
