import React from "react";
import { Divider, Grid2 as Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from "@mui/material";
import JobErButton from "components/JobErButton";
import JobErCard from "components/JobErCard";
import { useNavigate, createSearchParams } from "react-router-dom";
import StatusAvatar from "pages/portale_aziende/utils/StatusAvatar";

function HomeAziendeCandidature(props) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Grid size={{ xs: 12, md: 4 }}>
      <JobErCard
        title="Ultime Candidature ricevute"
        child={
          <Grid container>
            {props.candidature?.dati && props.candidature.dati.length > 0 ? (
              <Grid size={12} mt={2}>
                <List
                  sx={{
                    width: "100%",
                    maxHeight: "300px",
                    overflowY: "auto",
                  }}
                >
                  {props.candidature.dati.map((candidatura, index) => (
                    <React.Fragment key={"list_candidatura_" + index}>
                      <ListItem
                        alignItems="flex-start"
                        onClick={() => {
                          navigate({
                            pathname: "/Aziende/Candidature",
                            search: createSearchParams({
                              candidatura: candidatura.uuidCandidatura,
                            }).toString(),
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        key={candidatura.uuidCandidatura + "_listItem"}
                      >
                        <ListItemAvatar key={candidatura.uuidCandidatura + "_candidatura"}>
                          <StatusAvatar stato={candidatura.statoCandidatura} />
                        </ListItemAvatar>
                        <ListItemText
                          key={candidatura.uuidCandidatura + "_text"}
                          primary={candidatura?.uuidAnnuncio ? candidatura.titoloAnnuncio.toUpperCase() : "CANDIDATURA SPONTANEA"}
                          primaryTypographyProps={{
                            fontWeight: "bold",
                            color: theme.palette.primary.main,
                          }}
                          secondary={
                            <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.secondary">
                              {candidatura?.nominativo ? candidatura.nominativo.toUpperCase() : ""}
                            </Typography>
                          }
                        />
                      </ListItem>
                      {index < props.candidature.dati.length - 1 ? <Divider variant="inset" component="li" key={"divider_" + index} /> : null}
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
            ) : (
              <Grid size={12} mt={2} sx={{ minHeight: "100px" }} alignSelf="center">
                <Grid size={12} textAlign="center">
                  <Typography fontWeight="bold" sx={{ color: theme.palette.primary.main }}>
                    Nessuna candidatura
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid size={12} mt={2} textAlign="center">
              <JobErButton
                onClick={() => {
                  navigate("/Aziende/Candidature");
                }}
              >
                Gestisci Candidature
              </JobErButton>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
}

export default HomeAziendeCandidature;
