import pxToRem from "assets/theme-functions/pxToRem";

const baseProperties = {
  fontFamily: "Cabin",
  fontWeightLight: 200,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontSizeXXS: pxToRem(10),
  fontSizeXS: pxToRem(12),
  fontSizeSM: pxToRem(14),
  fontSizeMD: pxToRem(18),
  fontSizeLG: pxToRem(22),
  fontSizeXL: pxToRem(26),
};

const baseHeadingProperties = {
  fontFamily: baseProperties.fontFamily,
  fontWeight: baseProperties.fontWeightRegular,
};

const baseDisplayProperties = {
  fontFamily: baseProperties.fontFamily,
  fontWeight: baseProperties.fontWeightLight,
};

const typography = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightBold: baseProperties.fontWeightBold,

  h1: {
    fontSize: "33px",
    ...baseHeadingProperties,
  },

  h2: {
    fontSize: "26px",
    ...baseHeadingProperties,
  },

  h3: {
    fontSize: "24px",
    ...baseHeadingProperties,
  },

  h4: {
    fontSize: "22px",
    ...baseHeadingProperties,
  },

  h5: {
    fontSize: "20px",
    ...baseHeadingProperties,
  },

  h6: {
    fontSize: "14px",
    ...baseHeadingProperties,
  },

  p: {
    fontSize: "16px",
    ...baseDisplayProperties,
  },

  small: {
    fontSize: "13px",
    ...baseDisplayProperties,
  },

  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: "18px",
    textTransform: "uppercase",
  },

  caption: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.25,
  },

  overline: {
    fontFamily: baseProperties.fontFamily,
  },

  d1: {
    fontSize: pxToRem(80),
    ...baseDisplayProperties,
  },

  d2: {
    fontSize: pxToRem(72),
    ...baseDisplayProperties,
  },

  d3: {
    fontSize: pxToRem(64),
    ...baseDisplayProperties,
  },

  d4: {
    fontSize: pxToRem(56),
    ...baseDisplayProperties,
  },

  d5: {
    fontSize: pxToRem(48),
    ...baseDisplayProperties,
  },

  d6: {
    fontSize: pxToRem(40),
    ...baseDisplayProperties,
  },

  size: {
    xxs: baseProperties.fontSizeXXS,
    xs: baseProperties.fontSizeXS,
    sm: baseProperties.fontSizeSM,
    md: baseProperties.fontSizeMD,
    lg: baseProperties.fontSizeLG,
    xl: baseProperties.fontSizeXL,
  },
};

export default typography;
