import React, { useState } from "react";
import { Grid2 as Grid, Typography, Divider, useTheme } from "@mui/material";
import ChipsFiltriComuni from "./chips_filtri/ChipsFiltriComuni";
import ChipsFiltriSettori from "./chips_filtri/ChipsFiltriSettori";
import ViewAnnuncio from "./ViewAnnuncio";
import { useJobErController } from "context";
import { verificaVisibilitaAnnuncio } from "./utils/utils_annuncio";
import { handleCandidatura } from "./utils/utils_candidatura";

function ListaAnnunci(props) {
  const theme = useTheme();
  const [controller] = useJobErController();
  const { authUser } = controller;
  const [pending, setPending] = useState(false);

  let counter = 0;

  return (
    <Grid container mt={1} mb={2} spacing={2} sx={{ maxHeight: "69dvh", overflowY: "scroll" }}>
      {props.filtri && Object.keys(props.filtri).length > 0 ? (
        <Grid size={12}>
          <Grid container justifyContent="center" spacing={2}>
            {props.filtri?.provincia ? <ChipsFiltriComuni provincia={props.filtri.provincia} /> : null}
            {props.filtri?.comuni ? <ChipsFiltriComuni comuni={props.filtri.comuni} /> : null}
            {props.filtri?.professione ? <ChipsFiltriSettori settori={props.filtri.professione} /> : null}
          </Grid>
        </Grid>
      ) : null}
      {props.annunci ? (
        <Grid size={12} pb={2} pt={2}>
          <Typography variant="h7" fontWeight="bold" style={{ color: theme.palette.primary.main }}>
            Annunci trovati: {props.annunci.length}
          </Typography>
        </Grid>
      ) : null}
      {props.annunci
        ? props.annunci.map((annuncio, index) => {
            if (counter === 2) {
              counter = 0;
            }

            if (verificaVisibilitaAnnuncio(props.filtri, annuncio)) {
              counter++;

              return (
                <React.Fragment key={index}>
                  <Grid size={{ xs: 12, md: 5.5 }}>
                    <ViewAnnuncio
                      annuncio={annuncio}
                      index={index}
                      pending={pending}
                      searchParams={props.searchParams}
                      onSendCandidatura={() => {
                        handleCandidatura(annuncio, authUser, props.annunci, props.setAnnunci, setPending);
                      }}
                    />
                    <>
                      <br></br>
                      <Divider key={"divider_" + index} />
                      <br></br>
                    </>
                  </Grid>
                  {counter % 2 !== 0 && <Grid size={{ xs: 0, md: 1 }}></Grid>}
                </React.Fragment>
              );
            }
            return null;
          })
        : null}
    </Grid>
  );
}
export default ListaAnnunci;
