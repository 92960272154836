import React, { useContext } from "react";
import JobErModal from "components/JobErModal";
import JobErButton from "components/JobErButton";
import { ModalContext } from "context/modalLogin";
import { Grid2 as Grid } from "@mui/material";

function ModalDettagliAnnuncio(props) {
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);

  return (
    <JobErModal
      fullWidth={true}
      maxWidth={"sm"}
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      title={props.titolo}
      showCloseButton={true}
      modalBody={
        <Grid container justifyContent="center">
          <Grid size={12}>
            <div dangerouslySetInnerHTML={{ __html: props.descrizioneLavoro }} />
          </Grid>
        </Grid>
      }
      modalFooter={
        <JobErButton
          fullWidth={true}
          size="large"
          onClick={() => {
            props.setOpen(false);
            setIsModalOpen(true);
          }}
        >
          Candidati
        </JobErButton>
      }
    />
  );
}

export default ModalDettagliAnnuncio;
