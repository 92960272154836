import React from "react";
import JobErCard from "components/JobErCard";
import { Grid2 as Grid, Divider } from "@mui/material";
import options from "../../json/option_configuration.json";
import JobErButton from "components/JobErButton";
import Swal from "sweetalert2";
import JobErAutocomplete from "components/JobErAutocomplete";

function CardProfiloLingue(props) {
  const alertDeleteLingua = (uuid) => {
    Swal.fire({
      text: "Sei sicuro di voler cancellare questa lingua?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.onRemoveNestedData(uuid);
      } else if (result.isDenied) {
      }
    });
  };
  return (
    <JobErCard
      title="Lingue"
      style={{ minHeight: "300px" }}
      child={
        <Grid container justifyContent="center" spacing={2} mt={1}>
          {props.dati.map((lingua, index) => (
            <React.Fragment key={index}>
              {index === 0 && (
                <React.Fragment key={`${index}-first`}>
                  <Grid size={12}>
                    <JobErAutocomplete
                      size="small"
                      label="Lingua madre"
                      options={options.lingue}
                      value={lingua.linguaMadre ? options.lingue.find((opt) => opt.value.toLowerCase() === lingua.linguaMadre.toLowerCase()) : null}
                      onChange={(event, newValue) => {
                        props.onSetDati("linguaMadre", newValue ? newValue.label : null);
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 8 }}>
                    <JobErAutocomplete
                      size="small"
                      label="Seconda lingua"
                      options={options.lingue}
                      value={lingua.secondaLingua ? options.lingue.find((opt) => opt.value.toLowerCase() === lingua.secondaLingua.toLowerCase()) : null}
                      onChange={(event, newValue) => {
                        props.onSetDati("secondaLingua", newValue ? newValue.label : null);
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <JobErAutocomplete
                      size="small"
                      label="Livello"
                      options={options.livelli_lingue}
                      value={
                        lingua.livelloSecondaLingua
                          ? options.livelli_lingue.find((opt) => opt.label.toLowerCase() === lingua.livelloSecondaLingua.toLowerCase())
                          : null
                      }
                      onChange={(event, newValue) => {
                        props.onSetDati("livelloSecondaLingua", newValue ? newValue.label : null);
                      }}
                    />
                  </Grid>
                </React.Fragment>
              )}
              {index > 0 && (
                <>
                  <Grid size={12} m={1.5}>
                    <Divider />
                  </Grid>
                  <Grid size={{ xs: 12, md: 8 }}>
                    <JobErAutocomplete
                      size="small"
                      label="Seconda lingua"
                      options={options.lingue}
                      value={lingua?.secondaLingua ? options.lingue.find((opt) => opt.label.toLowerCase() === lingua.secondaLingua.toLowerCase()) : null}
                      onChange={(data, newValue) => {
                        props.onSetNestedDati(lingua.uuid, "secondaLingua", newValue?.label ? newValue?.label : null);
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <JobErAutocomplete
                      size="small"
                      label="Livello"
                      options={options.livelli_lingue}
                      value={
                        lingua?.livelloSecondaLingua
                          ? options.livelli_lingue.find((opt) => opt.label.toLowerCase() === lingua.livelloSecondaLingua.toLowerCase())
                          : null
                      }
                      onChange={(data, newValue) => {
                        props.onSetNestedDati(lingua.uuid, "livelloSecondaLingua", newValue?.label ? newValue?.label : null);
                      }}
                    />
                  </Grid>
                  <Grid size={12}>
                    <JobErButton size="small" color="error" onClick={() => alertDeleteLingua(lingua.uuid)}>
                      Rimuovi
                    </JobErButton>
                  </Grid>
                </>
              )}
            </React.Fragment>
          ))}
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12} mt={2}>
            <Grid container justifyContent="center">
              <Grid size="auto">
                <JobErButton size="small" color="success" onClick={() => props.onSave()}>
                  Salva
                </JobErButton>
                <JobErButton size="small" onClick={() => props.onAddNestedObject()}>
                  Aggiungi Lingua
                </JobErButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloLingue;
