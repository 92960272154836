import React, { useEffect, useState } from "react";
import JobErCard from "components/JobErCard";
import { Grid2 as Grid, Typography, Divider } from "@mui/material";
import JobErDropzone from "components/JobErDropzone";
import { getBase64, downloadDocumento } from "utils/utilsDati";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteDocumentoProfilo, salvaProfiloPersonaDocumento, deleteDocumentoProfiloByUuid } from "components/PortalePersone/utils/utils_profilo";
import { JobErToast } from "components/JobErToast";
import Swal from "sweetalert2";
import JobErButton from "components/JobErButton";

function CardProfiloDocumenti(props) {
  const [docsCv, setDocsCv] = useState([]);
  const [docsLm, setDocsLm] = useState([]);
  const [attestato, setAttestato] = useState([]);
  const [privacy, setPrivacy] = useState([]);
  const [permessoDiSoggiorno, setPermessoDiSoggiorno] = useState([]);

  const alertDeleteDocumento = (tipoDocumento, setVariabile) => {
    Swal.fire({
      text: "Sei sicuro di voler cancellare il documento?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDocumentoProfilo(props.authUser, tipoDocumento, props.dati, props.setDati, setVariabile);
      } else if (result.isDenied) {
      }
    });
  };

  const alertdeleteDocumentoProfiloByUuid = (setVariabile, uuid) => {
    Swal.fire({
      text: "Sei sicuro di voler cancellare il documento?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDocumentoProfiloByUuid(props.authUser, props.dati, props.setDati, setVariabile, uuid);
      } else if (result.isDenied) {
      }
    });
  };

  useEffect(() => {
    let arrayDocCv = [];
    let arrayDocLm = [];
    let arrayAttestati = [];
    let arrayPermessoSoggiorno = [];
    let arrayPrivacy = [];

    props.dati.forEach((element) => {
      if (element.documento && element.tipologiaDocumento === "cv") {
        arrayDocCv.push(element.documento);
      } else if (element.documento && element.tipologiaDocumento === "lm") {
        arrayDocLm.push(element.documento);
      } else if (element.documento && element.tipologiaDocumento === "attestato") {
        arrayAttestati.push(element.documento);
      } else if (element.documento && element.tipologiaDocumento === "permesso_soggiorno") {
        arrayPermessoSoggiorno.push(element.documento);
      } else if (element.documento && element.tipologiaDocumento === "privacy") {
        arrayPrivacy.push(element.documento);
      }
    });

    setDocsCv(arrayDocCv);
    setDocsLm(arrayDocLm);
    setAttestato(arrayAttestati);
    setPrivacy(arrayPrivacy);
    setPermessoDiSoggiorno(arrayPermessoSoggiorno);
  }, [props.dati]);

  return (
    <JobErCard
      title="Documenti"
      child={
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography variant="h6" fontWeight="bold" textAlign="left">
              Curriculum vitae
            </Typography>
          </Grid>
          {docsCv.length > 0 ? (
            <>
              <Grid size={{ xs: 6, md: 2 }}>
                <JobErButton
                  title="Scarica documento"
                  size="small"
                  onClick={() => {
                    let fileName = "cv_" + props.authUser.codiceFiscale;
                    downloadDocumento(docsCv[0], fileName);
                  }}
                >
                  <ArrowCircleDownIcon />
                </JobErButton>
              </Grid>
              <Grid Grid size={{ xs: 6, md: 2 }}>
                <JobErButton
                  title="Cancella documento"
                  size="small"
                  color="error"
                  onClick={() => {
                    alertDeleteDocumento("cv", setDocsCv);
                  }}
                >
                  <DeleteIcon />
                </JobErButton>
              </Grid>
            </>
          ) : (
            <Grid size={12}>
              <JobErDropzone
                maxFiles={1}
                files={docsCv}
                onRemoveFile={() => setDocsCv([])}
                onSetFiles={(files) =>
                  getBase64(files[0]).then((data) => {
                    if (data && data.includes("application/pdf")) {
                      salvaProfiloPersonaDocumento(props.authUser, data, "cv", props.dati, props.setDati);
                    } else {
                      JobErToast.fire({ icon: "error", title: "Puoi caricare solo PDF" });
                    }
                  })
                }
              />
            </Grid>
          )}
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12}>
            <Typography variant="h6" fontWeight="bold" textAlign="left">
              Lettera motivazionale
            </Typography>
          </Grid>
          {docsLm.length > 0 ? (
            <>
              <Grid size={{ xs: 6, md: 2 }}>
                <JobErButton
                  title="Scarica documento"
                  size="small"
                  onClick={() => {
                    let fileName = "lettera_motivazionale_" + props.authUser.codiceFiscale;
                    downloadDocumento(docsLm[0], fileName);
                  }}
                >
                  <ArrowCircleDownIcon />
                </JobErButton>
              </Grid>
              <Grid Grid size={{ xs: 6, md: 2 }}>
                <JobErButton
                  title="Cancella documento"
                  size="small"
                  color="error"
                  onClick={() => {
                    alertDeleteDocumento("lm", setDocsLm);
                  }}
                >
                  <DeleteIcon />
                </JobErButton>
              </Grid>
            </>
          ) : (
            <Grid size={12}>
              <JobErDropzone
                maxFiles={1}
                files={docsLm}
                onRemoveFile={() => setDocsLm([])}
                onSetFiles={(files) =>
                  getBase64(files[0]).then((data) => {
                    if (data && data.includes("application/pdf")) {
                      salvaProfiloPersonaDocumento(props.authUser, data, "lm", props.dati, props.setDati);
                    } else {
                      JobErToast.fire({ icon: "error", title: "Puoi caricare solo PDF" });
                    }
                  })
                }
              />
            </Grid>
          )}
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12}>
            <Typography variant="h6" fontWeight="bold" textAlign="left">
              Attestati
            </Typography>
          </Grid>
          {attestato.length > 0 ? (
            props.dati.map((obj) => {
              if (obj.tipologiaDocumento === "attestato") {
                return (
                  <>
                    <Grid size={{ xs: 6, md: 2 }}>
                      <JobErButton
                        title="Scarica documento"
                        size="small"
                        onClick={() => {
                          let fileName = "attestato_" + props.authUser.codiceFiscale;
                          downloadDocumento(obj.documento, fileName);
                        }}
                      >
                        <ArrowCircleDownIcon />
                      </JobErButton>
                    </Grid>
                    <Grid Grid size={{ xs: 6, md: 2 }}>
                      <JobErButton
                        title="Cancella documento"
                        size="small"
                        color="error"
                        onClick={() => {
                          alertdeleteDocumentoProfiloByUuid(setAttestato, obj.uuid);
                        }}
                      >
                        <DeleteIcon />
                      </JobErButton>
                    </Grid>
                  </>
                );
              }
              return null;
            })
          ) : (
            <Grid size={12}>
              <JobErDropzone
                maxFiles={5}
                files={attestato}
                onRemoveFile={() => setAttestato([])}
                onSetFiles={(newFiles) => {
                  // Process each uploaded file
                  newFiles.forEach((file) => {
                    getBase64(file).then((data) => {
                      if (data && data.includes("application/pdf")) {
                        // Save the document for each valid PDF file
                        salvaProfiloPersonaDocumento(props.authUser, data, "attestato", props.dati, props.setDati);
                      } else {
                        // Show an error for non-PDF files
                        JobErToast.fire({ icon: "error", title: "Puoi caricare solo PDF" });
                      }
                    });
                  });
                }}
              />
            </Grid>
          )}
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12}>
            <Typography variant="h6" fontWeight="bold" textAlign="left">
              Privacy
            </Typography>
          </Grid>
          {privacy.length > 0 ? (
            <>
              <Grid size={{ xs: 6, md: 2 }}>
                <JobErButton
                  title="Scarica documento"
                  size="small"
                  onClick={() => {
                    let fileName = "privacy_" + props.authUser.codiceFiscale;
                    downloadDocumento(privacy[0], fileName);
                  }}
                >
                  <ArrowCircleDownIcon />
                </JobErButton>
              </Grid>
              <Grid Grid size={{ xs: 6, md: 2 }}>
                <JobErButton
                  title="Cancella documento"
                  size="small"
                  color="error"
                  onClick={() => {
                    alertDeleteDocumento("privacy", setPrivacy);
                  }}
                >
                  <DeleteIcon />
                </JobErButton>
              </Grid>
            </>
          ) : (
            <Grid size={12}>
              <JobErDropzone
                maxFiles={1}
                files={privacy}
                onRemoveFile={() => setPrivacy([])}
                onSetFiles={(files) =>
                  getBase64(files[0]).then((data) => {
                    if (data && data.includes("application/pdf")) {
                      salvaProfiloPersonaDocumento(props.authUser, data, "privacy", props.dati, props.setDati);
                    } else {
                      JobErToast.fire({ icon: "error", title: "Puoi caricare solo PDF" });
                    }
                  })
                }
              />
            </Grid>
          )}
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12}>
            <Typography variant="h6" fontWeight="bold" textAlign="left">
              Permesso di soggiorno
            </Typography>
          </Grid>
          {permessoDiSoggiorno.length > 0 ? (
            <>
              <Grid size={{ xs: 6, md: 2 }}>
                <JobErButton
                  title="Scarica documento"
                  size="small"
                  onClick={() => {
                    let fileName = "permesso_soggiorno_" + props.authUser.codiceFiscale;
                    downloadDocumento(permessoDiSoggiorno[0], fileName);
                  }}
                >
                  <ArrowCircleDownIcon />
                </JobErButton>
              </Grid>
              <Grid Grid size={{ xs: 6, md: 2 }}>
                <JobErButton
                  title="Cancella documento"
                  size="small"
                  color="error"
                  onClick={() => {
                    alertDeleteDocumento("permesso_soggiorno", setPermessoDiSoggiorno);
                  }}
                >
                  <DeleteIcon />
                </JobErButton>
              </Grid>
            </>
          ) : (
            <Grid size={12}>
              <JobErDropzone
                maxFiles={1}
                files={permessoDiSoggiorno}
                onRemoveFile={() => setPermessoDiSoggiorno([])}
                onSetFiles={(files) =>
                  getBase64(files[0]).then((data) => {
                    if (data && data.includes("application/pdf")) {
                      salvaProfiloPersonaDocumento(props.authUser, data, "permesso_soggiorno", props.dati, props.setDati);
                    } else {
                      JobErToast.fire({ icon: "error", title: "Puoi caricare solo PDF" });
                    }
                  })
                }
              />
            </Grid>
          )}
        </Grid>
      }
    />
  );
}
export default CardProfiloDocumenti;
