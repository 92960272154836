import React, { useEffect, useState } from "react";
import JobErCard from "components/JobErCard";
import { Grid2 as Grid, Typography, List, ListItem, Divider, ListItemText, useTheme } from "@mui/material";
import { useJobErController } from "context";
import { useNavigate, createSearchParams } from "react-router-dom";
import { getCandidaturePersona } from "components/PortalePersone/utils/utils_candidatura";
import CircularProgress from "@mui/material/CircularProgress";

function CardCandidatureHomepage(props) {
  const theme = useTheme();
  const [candidature, setCandidature] = useState([]);
  const [controller] = useJobErController();
  const { authUser } = controller;
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (authUser?.codiceFiscale) {
      getCandidaturePersona(authUser, setCandidature, setLoading);
    }
  }, [authUser]);

  return (
    <JobErCard
      title="Le tue candidature"
      style={{ minHeight: "px" }}
      child={
        <Grid container mt={2} mb={2}>
          {candidature && loading === false ? (
            <Grid size={12}>
              <Typography
                color={theme.palette.primary.main}
                sx={{
                  textAlign: "center!important",
                }}
              >
                Ti sei candidato a: <b>{candidature.length === 1 ? candidature.length + " annuncio" : candidature.length + " annunci"}</b>
              </Typography>
            </Grid>
          ) : null}
          <Grid size={12}>
            {candidature && candidature.length > 0 && loading === false ? (
              <List
                sx={{
                  overflowY: "auto",
                }}
              >
                {candidature.map((candidatura, index) => (
                  <React.Fragment key={candidatura.uuidCandidatura}>
                    <ListItem
                      alignItems="flex-start"
                      onClick={() => {
                        navigate({
                          pathname: "/Persone/Candidature",
                          search: createSearchParams({
                            candidatura: candidatura.uuidCandidatura,
                          }).toString(),
                        });
                      }}
                      style={{ cursor: "pointer" }}
                      key={candidatura.uuidCandidatura}
                    >
                      {/* <ListItemAvatar key={candidatura.uuid + "_candidatura"}>
                        {candidatura.stato === 3 ? (
                          <Avatar
                            style={{
                              backgroundColor: theme.palette.warning.main,
                            }}
                          >
                            <HourglassBottomIcon />
                          </Avatar>
                        ) : candidatura.stato === 1 ? (
                          <Avatar
                            style={{
                              backgroundColor: theme.palette.success.main,
                            }}
                          >
                            <CheckIcon />
                          </Avatar>
                        ) : candidatura.stato === 4 ? (
                          <Avatar
                            style={{
                              backgroundColor: theme.palette.error.main,
                            }}
                          >
                            <ClearIcon />
                          </Avatar>
                        ) : null}
                      </ListItemAvatar> */}

                      <ListItemText
                        key={candidatura.uuid + "_text"}
                        primary={candidatura.titoloAnnuncio ? candidatura.titoloAnnuncio.toUpperCase() : "CANDIDATURA SPONTANEA"}
                        primaryTypographyProps={{
                          fontWeight: "bold",
                          color: theme.palette.primary.main,
                        }}
                        secondary={
                          <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.secondary">
                            {candidatura?.ragsMetopack || candidatura?.ragsPiva
                              ? candidatura?.ragsMetopack || candidatura?.ragsPiva
                              : candidatura?.anagraficaAzienda?.ragioneSociale}
                          </Typography>
                        }
                      />
                    </ListItem>
                    {index < candidature.length - 1 ? <Divider variant="inset" component="li" key={"divider_" + index} /> : null}
                  </React.Fragment>
                ))}
              </List>
            ) : null}
            {loading && (
              <Grid container justifyContent="center" mt={4}>
                <Grid size={12}>
                  <CircularProgress />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardCandidatureHomepage;
