import React, { useEffect, useState } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import JobErButton from "components/JobErButton";
import { asyncRicercaRuoli, asyncRicercaComuni2 } from "utils/utilsRicerca";
import { cercaAnnunciVetrina } from "./utils/UtilsVetrina";
import JobErAutocomplete from "components/JobErAutocomplete";
import { ProvinceER } from "utils/ProvinceER";
import CardAnnunciPersone from "components/Vetrina/PerLePersone/CardAnnunciPersone";

function RicercaLavoriPersona({ annunci, setAnnunci }) {
  const [formData, setFormData] = useState({ provincia: null, comune: null, professione: null });
  const [pendingFilter, setPendingFilter] = useState(false);
  const [messaggio, setMessaggio] = useState("");
  const [optionComuni, setOptionComuni] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [optionRuoli, setOptionRuoli] = useState([]);
  const [listaProvince, setListaProvince] = useState([]);

  useEffect(() => {
    if (process.env.REACT_APP_AMBIENTE === "modena-reggio") {
      setListaProvince([
        { label: "Modena", value: "MO" },
        { label: "Reggio Emilia", value: "RE" },
      ]);
    } else {
      setListaProvince(ProvinceER);
    }
  }, []);

  return (
    <>
      <Grid size={12}>
        <Typography variant="h1" fontWeight={"bold"} textAlign={"center"} color={"white.main"}>
          Che lavoro stai cercando?
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <JobErAutocomplete
          color="white"
          onClose={() => {
            setOptionRuoli([]);
          }}
          loading={loading2}
          size="small"
          label="Ricerca Professione"
          value={formData?.professione || null}
          onInput={(e) => {
            let inputValue = e.target.value;

            if (inputValue?.length >= 3) {
              setLoading2(true);
              asyncRicercaRuoli(inputValue).then((response) => {
                if (response) {
                  setOptionRuoli(response);
                  setLoading2(false);
                }
              });
            }
          }}
          options={optionRuoli}
          onChange={(data, newValue) => {
            setFormData({ ...formData, professione: newValue });
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <JobErAutocomplete
          color="white"
          size="small"
          label="Ricerca Provincia"
          options={listaProvince}
          value={formData.provincia}
          onChange={(event, newValue) => {
            setFormData({ ...formData, provincia: newValue, comune: null });
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <JobErAutocomplete
          color="white"
          onClose={() => {
            setOptionComuni([]);
          }}
          loading={loading2}
          size="small"
          label="Ricerca Comune"
          value={formData?.comune || null}
          onInput={(e) => {
            let inputValue = e.target.value;

            if (inputValue?.length >= 3) {
              setLoading2(true);
              asyncRicercaComuni2(inputValue, formData?.provincia?.value).then((response) => {
                if (response) {
                  setOptionComuni(response);
                  setLoading2(false);
                }
              });
            }
          }}
          options={optionComuni}
          onChange={(data, newValue) => {
            setFormData({ ...formData, comune: newValue });
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <JobErButton
          color="white"
          size="large"
          isLoading={pendingFilter}
          onClick={() => {
            cercaAnnunciVetrina(formData, setAnnunci, setPendingFilter, setMessaggio);
          }}
        >
          Cerca
        </JobErButton>
      </Grid>
      <Grid
        container
        backgroundColor={"primary.main"}
        sx={{
          display: { md: "flex" },
          pl: { xs: 2, md: 15 },
          pr: { xs: 2, md: 15 },

          pt: { xs: 4, md: 8 },
        }}
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        {annunci.length > 0 ? (
          <>
            {annunci.map((annuncio, index) => (
              <Grid size={{ xs: 12, md: 3 }}>
                <CardAnnunciPersone
                  gruppoLavoro={annuncio?.gruppo || null}
                  mansione={annuncio?.descrizione || null}
                  titolo={annuncio?.titoloAnnuncio || null}
                  descrizioneLavoro={annuncio?.descrizioneLavoro || null}
                  comune={annuncio?.comune || null}
                  posizioniAperte={annuncio?.posizioniAperte || null}
                />
              </Grid>
            ))}
          </>
        ) : (
          <Typography
            variant="p"
            color={"white.main"}
            sx={{
              textAlign: "center!important",
            }}
          >
            {messaggio}
          </Typography>
        )}
      </Grid>
    </>
  );
}
export default RicercaLavoriPersona;
