import pxToRem from "assets/theme-functions/pxToRem";

const fontSizes = {
	extraSmall: pxToRem(10),
	small: pxToRem(14),
	medium: pxToRem(18),
	large: pxToRem(22),
	extraLarge: pxToRem(26),
};

export default fontSizes;
