const textfield = {
  defaultProps: {
    disableInjectingGlobalStyles: true,
    variant: "outlined",
    color: "primary",
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      "& .MuiInputLabel-root": {
        color: theme.palette[ownerState.color].main,
      },
      "& .MuiFormHelperText-root": {
        color: theme.palette[ownerState.color].main,
      },
    }),
  },
};

export default textfield;
