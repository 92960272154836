import React from "react";
import { Grid2 as Grid, Typography, TextField, useTheme } from "@mui/material";
import JobErCard from "components/JobErCard";
import JobErDropzone from "components/JobErDropzone";
import { getBase64 } from "utils/utilsDati";
import JobErButton from "components/JobErButton";
import Swal from "sweetalert2";

function GestionePresentazione(props) {
  const theme = useTheme();
  return (
    <JobErCard
      title="Presentazione"
      child={
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid container mt={2}>
              <Grid size={12} textAlign="left" mb={1}>
                <Typography color={theme.palette.primary.main} fontWeight={"bold"}>
                  Copertina
                </Typography>
                {props.presentazione && props.presentazione.copertina ? (
                  <JobErButton
                    onClick={() => {
                      Swal.fire({
                        text: "Sei sicuro di voler eliminare la copertina?",
                        icon: "warning",
                        showCancelButton: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          props.onSetData("copertina", null);
                        } else if (result.isDenied) {
                        }
                      });
                    }}
                    color="error"
                  >
                    {"Elimina"}
                  </JobErButton>
                ) : null}
              </Grid>
              <Grid size={12}>
                {props.presentazione && props.presentazione.copertina ? (
                  <img
                    alt="presentazioneCopertina"
                    src={props.presentazione.copertina}
                    style={{
                      height: "150px",
                      width: "100%",
                      borderRadius: 15,
                    }}
                  />
                ) : (
                  <JobErDropzone
                    maxFiles={1}
                    acceptedFilesType={{
                      "image/png": [".png"],
                      "image/jpeg": [".jpg", ".jpeg"],
                    }}
                    files={null}
                    placeholder="Seleziona l'immagine di copertina"
                    onSetFiles={(files) =>
                      getBase64(files[0]).then((data) => {
                        props.onSetData("copertina", data);
                      })
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }} mt={2}>
            <Grid container>
              <Grid size={12} textAlign="left" mb={1}>
                <Typography color={theme.palette.primary.main} fontWeight={"bold"}>
                  Miniatura
                </Typography>
                {props.presentazione?.miniatura ? (
                  <JobErButton
                    onClick={() => {
                      Swal.fire({
                        text: "Sei sicuro di voler eliminare la miniatura?",
                        icon: "warning",
                        showCancelButton: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          props.onSetData("miniatura", null);
                        } else if (result.isDenied) {
                        }
                      });
                    }}
                    color="error"
                  >
                    {"Elimina"}
                  </JobErButton>
                ) : null}
              </Grid>
              <Grid size={12}>
                {props.presentazione?.miniatura ? (
                  <img
                    src={props.presentazione.miniatura}
                    alt="miniatura"
                    style={{
                      height: "150px",
                      width: "100%",
                      borderRadius: 15,
                    }}
                  />
                ) : (
                  <JobErDropzone
                    maxFiles={1}
                    acceptedFilesType={{
                      "image/png": [".png"],
                      "image/jpeg": [".jpg", ".jpeg"],
                    }}
                    files={null}
                    placeholder="Seleziona l'immagine di miniatura"
                    onSetFiles={(files) =>
                      getBase64(files[0]).then((data) => {
                        props.onSetData("miniatura", data);
                      })
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={12} mt={1}>
            <TextField
              fullWidth
              label="Sito web"
              size="small"
              value={props.presentazione ? props.presentazione.sitoWeb : ""}
              onChange={(e) => props.onSetData("sitoWeb", e.target.value)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              fullWidth
              label="Descrizione"
              size="small"
              multiline={true}
              rows={5}
              value={props.presentazione ? props.presentazione.descrizione : ""}
              onChange={(e) => props.onSetData("descrizione", e.target.value)}
            />
          </Grid>
          <Grid size={12} textAlign="center">
            <JobErButton color="success" onClick={() => props.onSave()}>
              {"Salva"}
            </JobErButton>
          </Grid>
        </Grid>
      }
    />
  );
}
export default GestionePresentazione;
