import React, { useEffect, useState } from "react";
import { Grid2 as Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, Divider, useTheme, Chip } from "@mui/material";
import JobErCard from "components/JobErCard";
import LayoutAziende from "components/PortaleAziende/LayoutAziende";
import { useJobErController } from "context";
import { getAnnunciAzienda, disattivaAnnuncio } from "./utils/utilsHomepage";
import StepperCreazioneAnnuncio from "components/PortaleAziende/StepperCreazioneAnnuncio";
import moment from "moment";
import StepperAnnuncioMobile from "components/PortaleAziende/StepperAnnuncioMobile";
import { VerificaStep } from "pages/portale_aziende/utils/VerificaStep";
import { useSearchParams } from "react-router-dom";
import { salvataggioAnnunci } from "pages/portale_aziende/utils/utilsAnnunci";
import { setStatoLabel, modificaAnnuncio } from "pages/portale_aziende/utils/SetStatoLabel";
import JobErButton from "components/JobErButton";
import DynamicAnnuncioStep from "./utils/DynamicAnnuncioStep";
import Swal from "sweetalert2";

function AnnunciAziende(props) {
  const theme = useTheme();
  const [annunci, setAnnunci] = useState({ dati: null, loading: true });
  const [newAnnuncio, setNewAnnuncio] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [stepWithError, setStepWithError] = useState([1]);
  const [stepCompleted, setStepCompleted] = useState([]);

  const [searchParams] = useSearchParams();

  const [datiAnnuncio, setDatiAnnuncio] = useState({
    dataPubblicazione: moment(new Date()).format("DD/MM/YYYY"),
  });

  const [controller] = useJobErController();
  const { authUser } = controller;

  useEffect(() => {
    if (authUser) {
      getAnnunciAzienda(localStorage.getItem("partitaIvaJob")).then((res) => {
        setAnnunci({ dati: res, loading: false });

        let uuid = null;

        searchParams.forEach((value, key) => {
          if (key === "uuid") {
            uuid = value;
          }
        });

        if (uuid) {
          let objAnnunci = res.filter((obj) => obj.uuid === uuid);

          if (objAnnunci.length > 0) {
            let selectedAnnuncio = objAnnunci[0];
            modificaAnnuncio(selectedAnnuncio, setNewAnnuncio, setActiveStep, setStepWithError, setStepCompleted, setDatiAnnuncio);
          } else {
          }
        }
      });
    }
  }, [authUser]);

  return (
    <LayoutAziende
      child={
        <Grid
          container
          spacing={2}
          sx={{
            padding: { xs: 2, md: 0 },
            backgroundColor: theme.palette.primary.main + "!important",
            minHeight: "100vh",
          }}
        >
          <Grid size={12}>
            <JobErCard
              title="Annunci"
              child={
                <Grid container>
                  <Grid
                    size={{ xs: 12, md: newAnnuncio ? 8 : 4 }}
                    alignSelf={newAnnuncio ? "start" : "center"}
                    style={{
                      transition: "1s",
                    }}
                    sx={{ marginBottom: { xs: 3, md: 0 } }}
                  >
                    <Grid container>
                      <Grid
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        size={12}
                      >
                        <JobErButton
                          size="small"
                          color={newAnnuncio ? "error" : "primary"}
                          onClick={() => {
                            setNewAnnuncio(!newAnnuncio);
                            setActiveStep(0);
                            setDatiAnnuncio({
                              dataPubblicazione: moment(new Date()).format("DD/MM/YYYY"),
                            });
                            setStepWithError([]);
                            setStepCompleted([]);
                          }}
                        >
                          {newAnnuncio ? "Chiudi" : "Crea nuovo annuncio"}
                        </JobErButton>
                        {newAnnuncio ? (
                          <JobErButton
                            size="small"
                            color="success"
                            onClick={() => {
                              salvataggioAnnunci(
                                datiAnnuncio,
                                stepWithError,
                                setStepWithError,
                                stepCompleted,
                                setStepCompleted,
                                annunci,
                                setAnnunci,
                                setNewAnnuncio,
                                newAnnuncio,
                                setActiveStep,
                                setDatiAnnuncio
                              );
                            }}
                          >
                            Salva
                          </JobErButton>
                        ) : null}
                      </Grid>
                      <Grid size={12} sx={{ display: { xs: "none", md: "block" } }}>
                        {newAnnuncio ? (
                          <Grid container justifyContent="center" mt={4}>
                            <Grid size={12}>
                              <StepperCreazioneAnnuncio
                                activeStep={activeStep}
                                onSetActiveStep={(step) => {
                                  setActiveStep(step);
                                }}
                                stepWithError={stepWithError}
                                stepCompleted={stepCompleted}
                              />
                            </Grid>
                          </Grid>
                        ) : null}
                        {newAnnuncio && activeStep >= 0 && activeStep <= 6 && (
                          <DynamicAnnuncioStep activeStep={activeStep} datiAnnuncio={datiAnnuncio} setDatiAnnuncio={setDatiAnnuncio} />
                        )}
                      </Grid>
                      <Grid size={12} sx={{ display: { xs: "block", md: "none" } }}>
                        {newAnnuncio ? (
                          <StepperAnnuncioMobile
                            activeStep={activeStep}
                            onSetActiveStep={(step) => {
                              VerificaStep(datiAnnuncio, stepWithError, setStepWithError, stepCompleted, setStepCompleted);
                              setActiveStep(step);
                            }}
                            datiAnnuncio={datiAnnuncio}
                            onSetDatiAnnuncio={(key, val) =>
                              setDatiAnnuncio({
                                ...datiAnnuncio,
                                [key]: val,
                              })
                            }
                            stepWithError={stepWithError}
                            stepCompleted={stepCompleted}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* annunci aziende */}
                  <Grid size={{ xs: 12, md: newAnnuncio ? 4 : 8 }} sx={{ transition: "1s" }}>
                    <Grid container>
                      <Grid size={12}>
                        <Typography component="h6" fontSize="18px" fontWeight="bold">
                          I tuoi annunci
                        </Typography>
                      </Grid>

                      {annunci?.dati && annunci.dati.length > 0 ? (
                        <Grid
                          size={12}
                          sx={{
                            borderLeft: {
                              xs: "none",
                              md: "1px solid lightgray",
                            },
                          }}
                        >
                          <List
                            sx={{
                              width: "100%",
                              maxHeight: "500px",
                              overflowY: "auto",
                            }}
                          >
                            {annunci.dati.map((annuncio, index) => (
                              <React.Fragment key={"list_annuncio_" + index}>
                                <ListItem alignItems="flex-start" key={annuncio.uuid}>
                                  <ListItemAvatar key={annuncio.uuid + "_candidatura"}>
                                    <img
                                      src={
                                        annuncio?.codificaMansione?.gruppo
                                          ? require("../../immagini/ambiti_lavoro/gr" + annuncio.codificaMansione.gruppo + "-260x145.jpg")
                                          : null
                                      }
                                      style={{
                                        height: "70px",
                                        width: "110px",
                                        borderRadius: 15,
                                      }}
                                      alt=""
                                    />
                                  </ListItemAvatar>

                                  <ListItemText
                                    key={annuncio.uuid + "_text"}
                                    sx={{ marginLeft: 2 }}
                                    primary={annuncio.titoloAnnuncio.toUpperCase()}
                                    primaryTypographyProps={{
                                      fontWeight: "bold",
                                      color: theme.palette.primary.main,
                                    }}
                                    secondary={
                                      <Grid container>
                                        <Grid size={12}>
                                          <Typography
                                            component="p"
                                            sx={{
                                              fontSize: "14px!important",
                                              color: theme.palette.primary.main,
                                            }}
                                          >
                                            {annuncio.descrizioneLavoro.length > 500
                                              ? annuncio.descrizioneLavoro.substring(0, 500).replace(/<[^>]*>?/gm, "") + " ..."
                                              : annuncio.descrizioneLavoro.replace(/<[^>]*>?/gm, "")}
                                          </Typography>
                                        </Grid>
                                        <Grid size={12} pt={1}>
                                          <JobErButton
                                            size="small"
                                            onClick={() => {
                                              modificaAnnuncio(annuncio, setNewAnnuncio, setActiveStep, setStepWithError, setStepCompleted, setDatiAnnuncio);
                                            }}
                                            color="primary"
                                          >
                                            Modifica
                                          </JobErButton>
                                          <JobErButton
                                            size="small"
                                            onClick={() => {
                                              Swal.fire({
                                                text: "Sei sicuro di voler eliminare questo annuncio?",
                                                icon: "warning",
                                                showCancelButton: true,
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  disattivaAnnuncio(annuncio.uuid, annunci, setAnnunci);
                                                } else if (result.isDenied) {
                                                }
                                              });
                                            }}
                                            color="error"
                                          >
                                            Disattiva
                                          </JobErButton>
                                          <Chip label={setStatoLabel(annuncio.stato)}></Chip>
                                        </Grid>
                                      </Grid>
                                    }
                                  />
                                </ListItem>
                                {index < annunci.dati.length - 1 ? <Divider variant="inset" component="li" key={"divider_" + index} /> : null}
                              </React.Fragment>
                            ))}
                          </List>
                        </Grid>
                      ) : (
                        <Grid
                          size={12}
                          sx={{
                            borderLeft: {
                              xs: "none",
                              md: "1px solid lightgray",
                            },
                            minHeight: "100px",
                          }}
                          mt={2}
                          alignSelf="center"
                        >
                          <Grid size={12} textAlign="center">
                            <Typography fontSize="14px" fontWeight="bold" sx={{ color: theme.palette.primary.main }}>
                              Nessun annuncio
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      }
    />
  );
}
export default AnnunciAziende;
