import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { JobErControllerProvider } from "context";
import { ModalProvider } from "context/modalLogin";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <JobErControllerProvider>
        <ModalProvider>
          <App />
        </ModalProvider>
      </JobErControllerProvider>
    </LocalizationProvider>
  </BrowserRouter>
);
