import LayoutConfartigianato from "components/PortaleConfartigianato/LayoutConfartigianato";
import { Grid2 as Grid, useTheme } from "@mui/material";
import JobErCard from "components/JobErCard";
import JobErTable from "components/JobErTable";
import { useEffect, useMemo, useState } from "react";
import Chip from "@mui/material/Chip";
import { getAziendeConfartigianato } from "components/PortaleConfartigianato/utils_confartigianato/aziendeConfartigianato";
import { useJobErController } from "context";

function AziendeConfartigianato(props) {
  const theme = useTheme();
  const [aziende, setAziende] = useState([]);
  const [pending, setPending] = useState(false);

  const [controller] = useJobErController();
  const { authUser } = controller;

  useEffect(() => {
    if (props.value === props.index) {
      if (authUser) {
        getAziendeConfartigianato(setAziende, authUser.codConfartigianato, setPending);
      }
    }
  }, [props.value]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "ragioneSociale",
        header: "Ragione Sociale",
      },
      {
        accessorKey: "partitaIva",
        header: "Partita Iva",
      },
      {
        accessorKey: "ricercheAttive",
        header: "Ricerche Attive",
        Cell: ({ row }) => {
          if (row.original.ricercheAttive > 0) {
            return (
              <Chip
                label={row.original.ricercheAttive + " Annunci"}
                variant="outlined"
                sx={{ color: theme.palette.success.main, borderColor: theme.palette.success.main }}
              />
            );
          } else {
            return (
              <Chip
                label={row.original.ricercheAttive + " Annunci"}
                sx={{ color: theme.palette.black.main, borderColor: theme.palette.black.main }}
                variant="outlined"
              />
            );
          }
        },
      },
      {
        accessorKey: "ricerchePassive",
        header: "Ricerche Passive",
        Cell: ({ row }) => {
          if (row.original.ricerchePassive > 0) {
            return (
              <Chip
                label={row.original.ricerchePassive + " Annunci"}
                variant="outlined"
                sx={{ color: theme.palette.success.main, borderColor: theme.palette.success.main }}
              />
            );
          } else {
            return (
              <Chip
                label={row.original.ricerchePassive + " Annunci"}
                sx={{ color: theme.palette.black.main, borderColor: theme.palette.black.main }}
                variant="outlined"
              />
            );
          }
        },
      },
      {
        accessorKey: "presentazione",
        header: "Presentazione",
        Cell: ({ row }) => {
          if (row.original.presentazione) {
            return <Chip label="Compilata" variant="outlined" sx={{ color: theme.palette.success.main, borderColor: theme.palette.success.main }} />;
          } else {
            return <Chip label="Non Compilata" variant="outlined" sx={{ color: theme.palette.error.main, borderColor: theme.palette.error.main }} />;
          }
        },
      },
      {
        accessorKey: "abilitazione",
        header: "Abilitazione",
        Cell: ({ row }) => {
          if (row.original.attivo > 0) {
            return <Chip label="Abilitata" variant="outlined" sx={{ color: theme.palette.success.main, borderColor: theme.palette.success.main }} />;
          } else {
            return <Chip label="Non Abilitata" variant="outlined" sx={{ color: theme.palette.error.main, borderColor: theme.palette.error.main }} />;
          }
        },
      },
    ],
    []
  );

  return props.value === props.index ? (
    <LayoutConfartigianato
      child={
        <Grid container>
          <Grid size={12}>
            <JobErCard
              title="Aziende"
              child={
                <Grid container>
                  <Grid size={12} pt={2}>
                    <JobErTable data={aziende} columns={columns} state={{ isLoading: pending }} />
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      }
    />
  ) : null;
}

export default AziendeConfartigianato;
