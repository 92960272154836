import React, { useState } from "react";
import { Grid2 as Grid, Typography, useTheme } from "@mui/material";
import options from "../../json/option_configuration.json";
import { asyncRicercaProgrammi } from "utils/utilsRicerca";
import JobErAutocomplete from "components/JobErAutocomplete";

function AnnuncioStep4(props) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [optionProgrammi, setOptionProgrammi] = useState([]);
  return (
    <Grid container spacing={3} mt={6}>
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          size="small"
          label="Richiesto titolo studio?"
          value={
            props.dati?.richiestoTitolo && typeof props.dati.richiestoTitolo === "object"
              ? props.dati.richiestoTitolo
              : props.dati && props.dati.titoloStudio
              ? {
                  value: "si",
                  label: "Sì",
                }
              : null
          }
          options={options.yes_no}
          onChange={(event, newValue) => {
            props.onSetDati("richiestoTitolo", newValue);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          size="small"
          label="Livello titolo"
          value={
            props.dati?.titoloStudio
              ? options.titoli_studio.find((opt) => opt.label === props.dati.titoloStudio || opt.value === props.dati.titoloStudio)
              : null
          }
          options={options.titoli_studio}
          onChange={(event, newValue) => {
            props.onSetDati("titoloStudio", newValue ? newValue.value : null);
          }}
          disabled={props.dati?.richiestoTitolo && props.dati.richiestoTitolo.value === "no" ? true : !props.dati.richiestoTitolo ? true : false}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          size="small"
          label="Specifica"
          value={
            props.dati?.specifica ? options.corso_frequentato.find((opt) => opt.label === props.dati.specifica || opt.value === props.dati.specifica) : null
          }
          options={options.corso_frequentato}
          onChange={(event, newValue) => {
            props.onSetDati("specifica", newValue ? newValue.value : null);
          }}
          disabled={props.dati?.richiestoTitolo && props.dati.richiestoTitolo.value === "no" ? true : !props.dati.richiestoTitolo ? true : false}
        />
      </Grid>

      <Grid size={12}>
        <Typography
          component="p"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold!important",
          }}
        >
          SOFTWARE
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <JobErAutocomplete
          onClose={() => {
            setOptionProgrammi([]);
          }}
          multiple
          loading={loading}
          size="small"
          label="Programmi"
          value={props.dati?.programmiRichiesti ? props.dati.programmiRichiesti : []}
          onInput={(e) => {
            let inputValue = e.target.value;

            if (inputValue?.length >= 3) {
              setLoading(true);
              asyncRicercaProgrammi(inputValue).then((response) => {
                if (response) {
                  setOptionProgrammi(response);
                  setLoading(false);
                }
              });
            }
          }}
          options={optionProgrammi}
          onChange={(data, newValue) => {
            props.onSetDati("programmiRichiesti", newValue);
          }}
        />
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep4;
