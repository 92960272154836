import React from "react";
import { ListItemIcon, ListItemText, ListItemButton, useTheme } from "@mui/material";

function JobErItemMenu(props) {
  const theme = useTheme();
  return (
    <ListItemButton
      sx={{
        backgroundColor: props.active ? theme.palette.grey.main + 44 : null,
        borderRadius: 4,
      }}
      onClick={() => props.onClick()}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText
        primary={props.title}
        style={{
          color: props.active ? theme.palette.primary.main : theme.palette.grey.main,
        }}
        primaryTypographyProps={{
          fontWeight: props.active ? "bold!important" : "regular!important",
        }}
      />
    </ListItemButton>
  );
}
export default JobErItemMenu;
