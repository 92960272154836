import React from "react";
import { Grid2 as Grid, Typography, useTheme } from "@mui/material";
import { ReactComponent as LOGO_CONFARTIGIANATO } from "../../../immagini/JOB_LOGO_CONFER_BLUE.svg";
import JobErButton from "components/JobErButton";

function SelezioneLogin(props) {
  const theme = useTheme();
  return (
    <Grid container style={{ padding: 30, paddingTop: 0 }}>
      {!props.noimage ? (
        <Grid size={12} textAlign="center" mb={2}>
          {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
            <img
              alt="immagine modena-reggio"
              src={require("../../../immagini/MyLapamJOB_Logo.png")}
              style={{
                height: "70px",
                width: "280px",
                borderRadius: 15,
                marginBottom: "20px",
              }}
            />
          ) : (
            <LOGO_CONFARTIGIANATO className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_lg"></LOGO_CONFARTIGIANATO>
          )}
        </Grid>
      ) : null}
      <Grid size={12} textAlign="center">
        <Typography variant="h6" fontWeight="bold" color={theme.palette.primary.main}>
          SELEZIONA TIPO ACCESSO
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }} mt={3} textAlign="center">
        <JobErButton
          size="large"
          onClick={() => {
            if (process.env.REACT_APP_AMBIENTE === "modena-reggio") {
              window.location.href = "https://my.lapam.eu";
              return null;
            } else {
              props.onSelectTipoAccesso("azienda");
            }
          }}
        >
          Azienda
        </JobErButton>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }} mt={3} textAlign="center">
        <JobErButton
          size="large"
          onClick={() => {
            props.onSelectTipoAccesso("persona");
          }}
        >
          Candidato
        </JobErButton>
      </Grid>
    </Grid>
  );
}
export default SelezioneLogin;
