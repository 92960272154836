import React, { useState } from "react";
import JobErCard from "components/JobErCard";
import { Divider, Grid2 as Grid, TextField } from "@mui/material";
import options from "../../json/option_configuration.json";
import { asyncRicercaRuoli } from "utils/utilsRicerca";
import JobErButton from "components/JobErButton";
import Swal from "sweetalert2";
import JobErAutocomplete from "components/JobErAutocomplete";

function CardProfiloEsperienzeLavorative(props) {
  const [optionRuoli, setOptionRuoli] = useState([]);

  const alertDeleteEsperienzeLavorative = (uuid) => {
    Swal.fire({
      text: "Sei sicuro di voler cancellare questa esperienza di lavoro?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.onRemoveNestedData(uuid);
      } else if (result.isDenied) {
      }
    });
  };
  return (
    <JobErCard
      title="Esperienze Lavorative"
      style={{ minHeight: "300px" }}
      child={
        <Grid container justifyContent="center" spacing={2} mt={1}>
          {props.dati.map((esperienza, index) => (
            <React.Fragment key={index}>
              {index === 0 && (
                <React.Fragment key={`${index}-first`}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <JobErAutocomplete
                      size="small"
                      label="Lavori ?"
                      options={options.yes_no}
                      value={
                        esperienza.lavoriAttualmente
                          ? options.yes_no.find((opt) => opt.label.toLowerCase() === esperienza.lavoriAttualmente.toLowerCase())
                          : null
                      }
                      onChange={(event, newValue) => {
                        props.onSetDati("lavoriAttualmente", newValue ? newValue.label : null);
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label="Data inizio"
                      type="date"
                      size="small"
                      value={esperienza?.dataInizio ? esperienza.dataInizio : " "}
                      onChange={(e) => props.onSetDati("dataInizio", e.target.value)}
                    />
                  </Grid>
                  <Grid size={12}>
                    <TextField
                      fullWidth
                      label="Azienda"
                      size="small"
                      value={esperienza?.nomeAzienda ? esperienza.nomeAzienda : ""}
                      onChange={(e) => props.onSetDati("nomeAzienda", e.target.value)}
                    />
                  </Grid>

                  <Grid size={12}>
                    <JobErAutocomplete
                      onClose={() => {
                        setOptionRuoli([]);
                      }}
                      size="small"
                      label="Ruolo"
                      value={
                        esperienza?.ruolo
                          ? {
                              value: esperienza.ruolo,
                              label: esperienza.ruolo,
                            }
                          : null
                      }
                      onInput={(e) => {
                        let inputValue = e.target.value;
                        if (inputValue?.length >= 3) {
                          asyncRicercaRuoli(inputValue).then((response) => {
                            if (response) {
                              setOptionRuoli(response);
                            }
                          });
                        }
                      }}
                      options={optionRuoli}
                      onChange={(data, newValue) => {
                        props.onSetDati("ruolo", newValue ? newValue.label : null);
                      }}
                    />
                  </Grid>
                  <Grid size={12}>
                    <JobErAutocomplete
                      size="small"
                      label="Tipologia contratto"
                      options={options.tipologie_contratto}
                      value={
                        esperienza?.tipologiaContratto
                          ? options.tipologie_contratto.find(
                              (opt) => opt.label === esperienza.tipologiaContratto || opt.value === esperienza.tipologiaContratto
                            )
                          : null
                      }
                      onChange={(event, newValue) => {
                        props.onSetDati("tipologiaContratto", newValue ? newValue.label : null);
                      }}
                    />
                  </Grid>
                  <Grid size={12}>
                    <TextField
                      fullWidth
                      label="Descrizione"
                      size="small"
                      value={esperienza?.descrizione ? esperienza.descrizione : ""}
                      onChange={(e) => props.onSetDati("descrizione", e.target.value)}
                    />
                  </Grid>
                </React.Fragment>
              )}
              {index > 0 && (
                <>
                  <Grid size={12} m={1.5}>
                    <Divider />
                  </Grid>
                  <Grid size={12}>
                    <TextField
                      fullWidth
                      label="Azienda"
                      size="small"
                      value={esperienza ? esperienza.nomeAzienda : ""}
                      onChange={(e) => props.onSetNestedDati(esperienza.uuid, "nomeAzienda", e.target.value)}
                    />
                  </Grid>
                  <Grid size={12}>
                    <JobErAutocomplete
                      onClose={() => {
                        setOptionRuoli([]);
                      }}
                      size="small"
                      label="Ruolo"
                      value={
                        esperienza?.ruolo
                          ? {
                              value: esperienza.ruolo,
                              label: esperienza.ruolo,
                            }
                          : null
                      }
                      onInput={(e) => {
                        let inputValue = e.target.value;
                        if (inputValue?.length >= 3) {
                          asyncRicercaRuoli(inputValue).then((response) => {
                            if (response) {
                              setOptionRuoli(response);
                            }
                          });
                        }
                      }}
                      options={optionRuoli}
                      onChange={(data, newValue) => {
                        props.onSetNestedDati(esperienza.uuid, "ruolo", newValue ? newValue.label : null);
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label="Data inizio"
                      type="date"
                      size="small"
                      value={esperienza?.dataInizio ? esperienza.dataInizio : " "}
                      onChange={(e) => props.onSetNestedDati(esperienza.uuid, "dataInizio", e.target.value)}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label="Data fine"
                      type="date"
                      size="small"
                      value={esperienza?.dataFine ? esperienza.dataFine : " "}
                      onChange={(e) => props.onSetNestedDati(esperienza.uuid, "dataFine", e.target.value)}
                    />
                  </Grid>
                  <Grid size={12}>
                    <JobErAutocomplete
                      size="small"
                      label="Tipologia contratto"
                      options={options.tipologie_contratto}
                      value={
                        esperienza?.tipologiaContratto
                          ? options.tipologie_contratto.find(
                              (opt) => opt.label === esperienza.tipologiaContratto || opt.value === esperienza.tipologiaContratto
                            )
                          : null
                      }
                      onChange={(event, newValue) => {
                        props.onSetNestedDati(esperienza.uuid, "tipologiaContratto", newValue ? newValue.label : null);
                      }}
                    />
                  </Grid>
                  <Grid size={12}>
                    <TextField
                      fullWidth
                      label="Descrizione"
                      size="small"
                      value={esperienza?.descrizione ? esperienza.descrizione : ""}
                      onChange={(e) => props.onSetNestedDati(esperienza.uuid, "descrizione", e.target.value)}
                    />
                  </Grid>
                  <Grid size={12}>
                    <JobErButton size="small" color="error" onClick={() => alertDeleteEsperienzeLavorative(esperienza.uuid)}>
                      {"Rimuovi"}
                    </JobErButton>
                  </Grid>
                </>
              )}
            </React.Fragment>
          ))}
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12} mt={2}>
            <Grid container justifyContent="center">
              <Grid size="auto" mr={1}>
                <JobErButton size="small" color="success" onClick={() => props.onSave()}>
                  Salva
                </JobErButton>
                <JobErButton size="small" onClick={() => props.onAddNestedObject()}>
                  Aggiungi esperienza{" "}
                </JobErButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloEsperienzeLavorative;
