import "./css/App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/vetrina/Home";
import Registrazione from "pages/vetrina/Registrazione";
import Persone from "pages/vetrina/Persone";
import Aziende from "pages/vetrina/Aziende";
import { useJobErController, setAuthUser } from "context";
import { useEffect, useMemo } from "react";
import HomepagePersone from "pages/portale_persone/HomePersone";
import AnnunciPersone from "pages/portale_persone/AnnunciPersone";
import CandidaturePersone from "pages/portale_persone/CandidaturePersone";
import ProfiloPersone from "pages/portale_persone/ProfiloPersone";
import { getUserInfoPersone, getUserInfoConfartigianato, getUserInfoAziende } from "utils/utilsLogin";
import HomepageAziende from "pages/portale_aziende/HomeAziende";
import ScrollToTop from "utils/ScrollToTop";
import AnnunciAziende from "pages/portale_aziende/AnnunciAziende";
import ProfiloAziende from "pages/portale_aziende/ProfiloAziende";
import CandidatureAziende from "pages/portale_aziende/CandidatureAziende";
import LoginConfartigianato from "components/PortaleConfartigianato/Login/LoginConfartigianato";
import PasswordDimenticata from "components/Vetrina/Login/PasswordDimenticata";
import HomeConfartigianato from "pages/portale_confartigianato/HomeConfartigianato";
import GestioneConfartigianato from "pages/portale_confartigianato/gestione/GestioneConfartigianato";
import AziendeConfartigianato from "pages/portale_confartigianato/aziende/AziendeConfartigianato";
import { isTokenExpired } from "utils/utilsLogin";
import { useNavigate } from "react-router-dom";
import { ProtectedRoute } from "utils/ProtectedRoute";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { themeFunction } from "assets/theme/themeFunction";
import ChiSiamo from "pages/vetrina/ChiSiamo";
import Contattaci from "pages/vetrina/Contattaci";

function App() {
  const [controller, dispatch] = useJobErController();
  const navigate = useNavigate();
  const theme = useMemo(() => createTheme(themeFunction()), []);

  useEffect(() => {
    /* setto il titolo header corretto */
    const environment = process.env.REACT_APP_AMBIENTE;
    const title = environment === "modena-reggio" ? "MyLapam Job" : "Job Confartigianato";
    document.title = title;

    let faviconUrl;

    if (environment === "modena-reggio") {
      faviconUrl = "/favicon.ico";
    } else {
      faviconUrl = "/AdiArtigianato.svg";
    }

    const link = document.createElement("link");
    link.rel = "icon";
    link.href = faviconUrl;

    const existingFavicon = document.querySelector('link[rel="icon"]');
    if (existingFavicon) {
      document.head.removeChild(existingFavicon);
    }

    document.head.appendChild(link);

    /* controllo se la jwt è scaduta */
    let accessToken = localStorage.getItem("accessTokenJob");

    if (accessToken) {
      if (isTokenExpired(accessToken)) {
        localStorage.removeItem("accessTokenJob");
        localStorage.removeItem("partitaIvaJob");
        navigate("/");
      }
    }

    if (accessToken && accessToken !== "null") {
      let decJwt = atob(accessToken.split(".")[1]);
      if (JSON.parse(decJwt)["exp"] * 1000 > Date.now()) {
        if (JSON.parse(decJwt).section === "persone") {
          getUserInfoPersone()
            .then((user) => {
              if (user) {
                setAuthUser(dispatch, user);
              }
            })
            .catch(function (e) {
              console.error("errore get info persona");
            });
        } else if (JSON.parse(decJwt).section === "aziende") {
          let partitaIva = localStorage.getItem("partitaIvaJob");
          if (partitaIva) {
            getUserInfoAziende(partitaIva)
              .then((user) => {
                if (user) {
                  setAuthUser(dispatch, user);
                }
              })
              .catch(function (e) {
                console.error("errore get info azienda");
              });
          }
        } else if (JSON.parse(decJwt).section === "confartigianato") {
          getUserInfoConfartigianato()
            .then((user) => {
              if (user) {
                setAuthUser(dispatch, user);
              }
            })
            .catch(function (e) {
              console.error("errore get info confartigianato");
            });
        }
      }
    } else {
      localStorage.removeItem("accessTokenJob");
      localStorage.removeItem("partitaIvaJob");
    }
  }, [dispatch, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ScrollToTop />
        <Routes>
          <Route exact path="/Vetrina/Home" element={<Home />}></Route>
          <Route exact path="*" element={<Home />}></Route>
          <Route exact path="/Vetrina/Registrati" element={<Registrazione />}></Route>
          <Route exact path="/PasswordDimenticata" element={<PasswordDimenticata />}></Route>
          <Route exact path="/Vetrina/Persone" element={<Persone />}></Route>
          <Route exact path="/Vetrina/Aziende" element={<Aziende />}></Route>
          <Route exact path="/Vetrina/ChiSiamo" element={<ChiSiamo />}></Route>
          <Route exact path="/Vetrina/Contattaci" element={<Contattaci />}></Route>
          <Route
            exact
            path="/Persone/Home"
            element={
              <ProtectedRoute>
                <HomepagePersone />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/Persone/Annunci"
            element={
              <ProtectedRoute>
                <AnnunciPersone />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/Persone/Candidature"
            element={
              <ProtectedRoute>
                <CandidaturePersone />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/Persone/Profilo"
            element={
              <ProtectedRoute>
                <ProfiloPersone />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/Aziende/Home"
            element={
              <ProtectedRoute>
                <HomepageAziende />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/Aziende/Annunci"
            element={
              <ProtectedRoute>
                <AnnunciAziende />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/Aziende/Profilo"
            element={
              <ProtectedRoute>
                <ProfiloAziende />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/Aziende/Candidature"
            element={
              <ProtectedRoute>
                <CandidatureAziende />
              </ProtectedRoute>
            }
          ></Route>
          {process.env.REACT_APP_AMBIENTE !== "modena-reggio" && (
            <>
              <Route exact path="/Confartigianato/Login" element={<LoginConfartigianato />}></Route>
              <Route
                exact
                path="/Confartigianato/Home"
                element={
                  <ProtectedRoute>
                    <HomeConfartigianato />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                exact
                path="/Confartigianato/Gestione"
                element={
                  <ProtectedRoute>
                    <GestioneConfartigianato />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                exact
                path="/Confartigianato/Aziende"
                element={
                  <ProtectedRoute>
                    <AziendeConfartigianato />
                  </ProtectedRoute>
                }
              ></Route>
            </>
          )}
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
