import React, { useState } from "react";
import { asyncRicercaComuni, asyncRicercaRuoli } from "utils/utilsRicerca";
import { Collapse, Grid2 as Grid } from "@mui/material";
import JobErButton from "components/JobErButton";
import { ProvinceER } from "utils/ProvinceER";
import JobErAutocomplete from "components/JobErAutocomplete";

function SezioneFiltriAnnunci(props) {
  const [open, setOpen] = useState(true);
  const [optionRuoli, setOptionRuoli] = useState([]);
  const [optionComuni, setOptionComuni] = useState([]);

  ProvinceER.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Grid container mt={1} mb={2} justifyContent="center">
      <Grid size={12} sx={{ display: { xs: "block", md: "none" } }} mb={1}>
        <JobErButton size="small" onClick={() => setOpen(!open)}>
          {open ? "Nascondi Filtri" : "Visualizza Filtri"}
        </JobErButton>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 4 }} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErAutocomplete
              size="small"
              multiple
              label="Ricerca la provincia"
              options={ProvinceER}
              value={props.filter.provincia}
              onChange={(event, newValue) => {
                props.onSetFilter("provincia", newValue);
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErAutocomplete
              onClose={() => {
                setOptionComuni([]);
              }}
              multiple
              size="small"
              label="Ricerca il comune"
              value={props.filter.comuni}
              onInput={(e) => {
                let inputValue = e.target.value;
                if (inputValue?.length >= 3) {
                  asyncRicercaComuni(inputValue).then((response) => {
                    if (response) {
                      setOptionComuni(response);
                    }
                  });
                }
              }}
              options={optionComuni}
              onChange={(data, newValue) => {
                props.onSetFilter("comuni", newValue);
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErAutocomplete
              onClose={() => {
                setOptionRuoli([]);
              }}
              multiple
              size="small"
              label="Ricerca la professione"
              value={props.filter.professione}
              onInput={(e) => {
                let inputValue = e.target.value;
                if (inputValue?.length >= 3) {
                  asyncRicercaRuoli(inputValue).then((response) => {
                    if (response) {
                      setOptionRuoli(response);
                    }
                  });
                }
              }}
              options={optionRuoli}
              onChange={(data, newValue) => {
                props.onSetFilter("professione", newValue);
              }}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
}
export default SezioneFiltriAnnunci;
