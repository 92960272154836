import React from "react";
import { Grid2 as Grid, TextField } from "@mui/material";
import options from "../../json/option_configuration.json";
import { convertRetribuzioneSuppToOptSelect, convertBenefitToOptSelect } from "pages/portale_aziende/utils/utilsAnnunci";
import JobErAutocomplete from "components/JobErAutocomplete";

function AnnuncioStep2(props) {
  return (
    <Grid container spacing={3} mt={6}>
      <Grid size={{ xs: 12, md: 3 }}>
        <JobErAutocomplete
          size="small"
          label="Richiesta esperienza? *"
          value={
            props.dati?.richiestaEsperienza && typeof props.dati?.richiestaEsperienza === "object"
              ? props.dati?.richiestaEsperienza
              : props.dati?.richiestaEsperienza && typeof props.dati?.richiestaEsperienza === "string"
              ? options.yes_no.find((opt) => opt.value === props.dati?.richiestaEsperienza)
              : null
          }
          options={options.yes_no}
          onChange={(event, newValue) => {
            props.onSetDati("richiestaEsperienza", newValue);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <TextField
          fullWidth
          label={
            props.dati && props.dati.richiestaEsperienza && props.dati.richiestaEsperienza.value === "no"
              ? "Anni"
              : !props.dati.richiestaEsperienza && !props.dati.anniEsperienza
              ? "Anni"
              : "Anni *"
          }
          type="number"
          size="small"
          disabled={
            props.dati && props.dati.richiestaEsperienza && props.dati.richiestaEsperienza.value === "no"
              ? true
              : !props.dati.richiestaEsperienza && !props.dati.anniEsperienza
              ? true
              : false
          }
          value={props.dati && props.dati.anniEsperienza ? props.dati.anniEsperienza : ""}
          onChange={(e) => props.onSetDati("anniEsperienza", e.target.value)}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <TextField
          fullWidth
          label="Posizioni aperte *"
          type="number"
          size="small"
          disabled={
            props.dati && props.dati.richiestaEsperienza && props.dati.richiestaEsperienza.value === "no"
              ? true
              : !props.dati.richiestaEsperienza && !props.dati.anniEsperienza
              ? true
              : false
          }
          value={props.dati && props.dati.posizioniAperte ? props.dati.posizioniAperte : ""}
          onChange={(e) => props.onSetDati("posizioniAperte", e.target.value)}
        />
      </Grid>

      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          size="small"
          label="Tipologia contratto"
          value={
            props.dati && props.dati.tipologiaContratto
              ? options.tipologie_contratto.find((opt) => opt.label === props.dati.tipologiaContratto || opt.value === props.dati.tipologiaContratto)
              : null
          }
          options={options.tipologie_contratto}
          onChange={(event, newValue) => {
            props.onSetDati("tipologiaContratto", newValue ? newValue.value : null);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <JobErAutocomplete
          size="small"
          label="Dettaglio compenso"
          value={
            props.dati && props.dati.dettaglioCompenso
              ? props.dati.dettaglioCompenso
              : props.dati.nettoMensile
              ? {
                  value: "netto",
                  label: "Netto",
                }
              : props.dati.ral
              ? {
                  value: "ral",
                  label: "Ral",
                }
              : null
          }
          options={options.dettaglio_compenso}
          onChange={(event, newValue) => {
            props.onSetDati("dettaglioCompenso", newValue);
          }}
        />
      </Grid>
      {props.dati && (props.dati.dettaglioCompenso || props.dati.nettoMensile || props.dati.ral) ? (
        <Grid size={{ xs: 12, md: 3 }}>
          <TextField
            fullWidth
            label={props.dati && props.dati.dettaglioCompenso ? (props.dati.dettaglioCompenso.value === "netto" ? "Inserisci netto" : "Inserisci ral") : ""}
            type="number"
            size="small"
            value={
              props.dati && props.dati.stipendio
                ? props.dati.stipendio
                : props.dati.nettoMensile
                ? props.dati.nettoMensile
                : props.dati.ral
                ? props.dati.ral
                : null
            }
            onChange={(e) => props.onSetDati("stipendio", e.target.value)}
          />
        </Grid>
      ) : null}
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          multiple
          size="small"
          label="Retribuzione supplementare"
          value={
            props.dati?.retribuzioneSupplementare && typeof props.dati.retribuzioneSupplementare === "object"
              ? props.dati.retribuzioneSupplementare
              : props.dati?.retribuzioneSupplementare && typeof props.dati.retribuzioneSupplementare === "string"
              ? convertRetribuzioneSuppToOptSelect(props.dati.retribuzioneSupplementare)
              : []
          }
          options={options.retribuzione_supplementare}
          onChange={(event, newValue) => {
            props.onSetDati("retribuzioneSupplementare", newValue);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          multiple
          size="small"
          label="Benefit"
          value={
            props.dati?.benefit && typeof props.dati.benefit === "object"
              ? props.dati.benefit
              : props.dati?.benefit && typeof props.dati.benefit === "string"
              ? convertBenefitToOptSelect(props.dati.benefit)
              : []
          }
          options={options.benefit}
          onChange={(event, newValue) => {
            props.onSetDati("benefit", newValue);
          }}
        />
      </Grid>
      <Grid size={12}>
        <TextField
          fullWidth
          label="Descrizione lavoro *"
          type="number"
          size="small"
          value={props.dati && props.dati.descrizioneLavoro ? props.dati.descrizioneLavoro : ""}
          onChange={(e) => props.onSetDati("descrizioneLavoro", e.target.value)}
          multiline
          rows={5}
        />
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep2;
