import React from "react";
import { Grid2 as Grid, Typography, useTheme } from "@mui/material";
import options from "../../json/option_configuration.json";
import { convertLinguaToOptSelect, convertLivelloLinguaToOptSelect } from "pages/portale_aziende/utils/utilsAnnunci";
import JobErAutocomplete from "components/JobErAutocomplete";

function AnnuncioStep5(props) {
  const theme = useTheme();
  return (
    <Grid container spacing={3} mt={6}>
      <Grid size={12}>
        <Typography
          component="p"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold!important",
          }}
        >
          LINGUA MADRE
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          size="small"
          label="Lingua richiesta"
          value={
            props.dati && props.dati.linguaPrincipale && typeof props.dati.linguaPrincipale === "object"
              ? props.dati.linguaPrincipale
              : props.dati && typeof props.dati.linguaPrincipale === "string"
              ? convertLinguaToOptSelect(props.dati.linguaPrincipale)
              : null
          }
          options={options.lingue}
          onChange={(event, newValue) => {
            props.onSetDati("linguaPrincipale", newValue);
          }}
        />
      </Grid>

      <Grid size={12}>
        <Typography
          component="p"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold!important",
          }}
        >
          SECONDA LINGUA
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          size="small"
          label="Seconda lingua"
          value={
            props.dati && props.dati.linguaSecondaria && typeof props.dati.linguaSecondaria === "object"
              ? props.dati.linguaSecondaria
              : props.dati && typeof props.dati.linguaSecondaria === "string"
              ? convertLinguaToOptSelect(props.dati.linguaSecondaria)
              : null
          }
          options={options.lingue}
          onChange={(event, newValue) => {
            props.onSetDati("linguaSecondaria", newValue);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          size="small"
          label="Livello"
          value={
            props.dati && props.dati.livelloLinguaSecondaria && typeof props.dati.livelloLinguaSecondaria === "object"
              ? props.dati.livelloLinguaSecondaria
              : props.dati && typeof props.dati.livelloLinguaSecondaria === "string"
              ? convertLivelloLinguaToOptSelect(props.dati.livelloLinguaSecondaria)
              : null
          }
          options={options.livelli_lingue}
          onChange={(event, newValue) => {
            props.onSetDati("livelloLinguaSecondaria", newValue);
          }}
        />
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep5;
