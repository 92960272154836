import React from "react";
import { Grid2 as Grid, Typography, Card, CardMedia, CardContent, Chip, useTheme } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { decodeHTMLEntities } from "utils/utilsDati";

function CardAnnunciPersone(props) {
  const theme = useTheme();
  const navigate = useNavigate();

  let description = null;

  if (props.descrizioneLavoro) {
    // Helper function to capitalize the first letter
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    // Remove HTML tags and convert to lowercase
    const cleanDescription = decodeHTMLEntities(props.descrizioneLavoro.toLowerCase());

    if (cleanDescription.length > 105) {
      description = capitalizeFirstLetter(cleanDescription.substring(0, 90)) + " ...";
    } else {
      description = capitalizeFirstLetter(cleanDescription);
    }
  }

  return (
    <Card
      sx={{
        borderRadius: 10,
        width: 250,
        maxHeight: 300,
        minHeight: 300,
      }}
      onClick={() =>
        navigate({
          pathname: "/Persone/Annunci",
          search: createSearchParams({
            annuncio: props.uuid,
          }).toString(),
        })
      }
      style={{ cursor: "pointer" }}
    >
      <CardMedia
        component="img"
        height="140"
        image={props.gruppoLavoro ? require(`../../immagini/ambiti_lavoro/gr${props.gruppoLavoro}-260x145.jpg`) : null}
        style={{ borderRadius: 20 }}
      />
      <CardContent>
        <Grid container justifyContent="center" mb={1}>
          <Grid size={12}>
            <Chip
              label={props.mansione}
              size="small"
              className="ChipJobER"
              style={{
                color: "white",
                backgroundColor: theme.palette.primary.main,
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={12}>
            <Typography
              variant="h6"
              style={{
                textAlign: "left",
              }}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CardAnnunciPersone.propTypes = {
  descrizioneLavoro: PropTypes.string,
  gruppoLavoro: PropTypes.number,
  mansione: PropTypes.string,
  uuid: PropTypes.string,
};

export default CardAnnunciPersone;
