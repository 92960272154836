import React, { useState, useEffect, cloneElement } from "react";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useJobErController } from "context";
import Loading from "components/JobErLoading";

export const ProtectedRoute = ({ children }) => {
  const [controller] = useJobErController();
  const { authUser } = controller;
  const [url, setUrl] = useState(null);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    setUrl(searchParams);
  }, []);

  if (!localStorage.getItem("accessTokenJob")) {
    return <Navigate to="/Login" />;
  } else if (authUser && url) {
    return cloneElement(children, {
      url: url,
    });
  } else {
    return <Loading />;
  }
};
