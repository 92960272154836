export function getAuthHeader() {
  const token = localStorage.getItem("accessTokenJob");
  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}

export function getAuthHeaderVetrina() {
  return { Accept: "application/json" };
}
