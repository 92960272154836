import React from "react";
import Chip from "@mui/material/Chip";
import SchoolIcon from "@mui/icons-material/School";
import { Grid2 as Grid, Avatar, useTheme } from "@mui/material";

function ChipsStudi(props) {
  const theme = useTheme();
  return props.studi ? (
    <Grid size="auto">
      <Chip
        avatar={
          <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
            <SchoolIcon sx={{ fontSize: "18px", color: theme.palette.white.main }} />
          </Avatar>
        }
        label={
          props.studi === "scuola_media"
            ? "Scuola Media"
            : props.studi === "scuola_superiore_professionale"
            ? "Scuola Superiore Professionale"
            : props.studi === "scuola_superiore_tecnico"
            ? "Scuola Superiore Tecnico"
            : props.studi === "scuola_superiore_liceo"
            ? "Scuola Superiore Liceo"
            : props.studi === "laurea_triennale"
            ? "Laurea Triennale"
            : props.studi === "laurea_magistrale"
            ? "Laurea Magistrale"
            : props.studi === "corso_ifts"
            ? "Corso IFTS"
            : props.studi === "corso_its"
            ? "Corso ITS"
            : "Non Specificato"
        }
        sx={{
          "& .MuiChip-label": {
            fontSize: "14px",
          },
          "& .MuiChip-deleteIcon": {
            color: theme.palette.error.main,
            fontSize: "14px",
          },
        }}
      />
    </Grid>
  ) : null;
}
export default ChipsStudi;
