import React, { useEffect, useState } from "react";
import JobErCard from "components/JobErCard";
import { Grid2 as Grid, Typography, List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, TextField, useTheme } from "@mui/material";
import { getAziendeHomepage } from "components/PortalePersone/utils/utils_homepage";
import CardAziendeHomepageDettaglio from "./CardAziendeHomepageDettaglio";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import JobErButton from "components/JobErButton";
import JobErAutocomplete from "components/JobErAutocomplete";
import { ProvinceER } from "utils/ProvinceER";

function CardAziendeHomepage(props) {
  const [provinciaSelezionata, setProvinciaSelezionata] = useState(null);
  const [aziendeTerritorio, setAziendeTerritorio] = useState([]);
  const [open, setOpen] = useState(false);
  const [aziendaSelezionata, setAziendaSelezionata] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState({});
  const [filtroAzienda, setFiltroAzienda] = useState("");
  const [optionProv, setOptionProv] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    if (provinciaSelezionata) {
      setAziendeTerritorio([]);
      getAziendeHomepage(provinciaSelezionata, setAziendeTerritorio, setLoading, page, setPagination, filtroAzienda);
    }
  }, [provinciaSelezionata, page]);

  useEffect(() => {
    if (process.env.REACT_APP_AMBIENTE !== "modena-reggio") {
      setOptionProv(ProvinceER);
    }
  }, []);

  return (
    <JobErCard
      title="Le nostre aziende"
      style={{ minHeight: "300px" }}
      child={
        <Grid container mt={2} mb={2} justifyContent="center">
          {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
            <>
              <Grid size={{ xs: 6, md: 4 }} mt={2} mb={2}>
                <JobErButton
                  size="small"
                  variant={provinciaSelezionata?.value === "MO" ? "contained" : "outlined"}
                  onClick={() => {
                    const newProvincia = {
                      label: "Modena e Provincia",
                      value: "MO",
                      idmap: "MO",
                    };

                    setProvinciaSelezionata(newProvincia);
                  }}
                >
                  {"Modena e Prov"}
                </JobErButton>
              </Grid>
              <Grid size={{ xs: 6, md: 4 }} mt={2} mb={2}>
                <JobErButton
                  size="small"
                  variant={provinciaSelezionata?.value === "RE" ? "contained" : "outlined"}
                  onClick={() => {
                    const newProvincia = {
                      label: "Reggio Emilia e Provincia",
                      value: "RE",
                      idmap: "RE",
                    };

                    setProvinciaSelezionata(newProvincia);
                  }}
                >
                  {"Reggio Emilia e Prov"}
                </JobErButton>
              </Grid>
            </>
          ) : (
            <Grid size={{ xs: 12, md: 6 }}>
              <JobErAutocomplete
                size="small"
                label="Ricerca Provincia"
                value={provinciaSelezionata}
                options={optionProv}
                onChange={(data, newValue) => {
                  setProvinciaSelezionata(newValue);
                }}
              />
            </Grid>
          )}

          <Grid size={{ xs: 12, md: 12 }}>
            <Grid container justifyContent="center">
              {process.env.REACT_APP_AMBIENTE === "modena-reggio" && loading === false && provinciaSelezionata && (
                <>
                  <Grid size={{ xs: 12, md: 6 }} mt={2} mb={2}>
                    <TextField
                      fullWidth
                      label="Ricerca ragione sociale..."
                      size="small"
                      value={filtroAzienda ? filtroAzienda : ""}
                      onChange={(e) => setFiltroAzienda(e.target.value)}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 2 }} mt={2} mb={2}>
                    <JobErButton
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        getAziendeHomepage(provinciaSelezionata, setAziendeTerritorio, setLoading, page, setPagination, filtroAzienda);
                      }}
                    >
                      {"Cerca"}
                    </JobErButton>
                  </Grid>
                </>
              )}
              {provinciaSelezionata && loading === false && (
                <Grid size={12} align="center" mt={1}>
                  <Typography variant="h6" color={theme.palette.primary.main}>
                    Aziende trovate: <b>{pagination?.totalElements}</b>
                  </Typography>
                </Grid>
              )}
              <Grid size={12}>
                {aziendeTerritorio.length > 0 && loading === false ? (
                  <>
                    <List
                      sx={{
                        width: "100%",
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {aziendeTerritorio.map((azienda, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            alignItems="flex-start"
                            key={azienda.partitaIva + "_" + index}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setAziendaSelezionata(azienda);
                              setOpen(true);
                            }}
                          >
                            {azienda?.miniatura ? (
                              <ListItemAvatar>
                                <Avatar src={azienda.miniatura} />
                              </ListItemAvatar>
                            ) : (
                              <ListItemAvatar>
                                <Avatar>{azienda?.ragioneSociale ? azienda?.ragioneSociale?.substring(0, 2).toUpperCase() : ""}</Avatar>
                              </ListItemAvatar>
                            )}

                            <ListItemText
                              primary={azienda?.ragioneSociale ? azienda?.ragioneSociale.toUpperCase() : ""}
                              primaryTypographyProps={{
                                fontWeight: "bold",
                                color: theme.palette.primary.main,
                              }}
                              secondary={
                                <Grid container>
                                  {/* <Grid size={12} mt={1}>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {azienda?.com?.des ? azienda?.com?.des : null}
                                  </Typography>
                                </Grid> */}
                                  {/* <Grid size={12}>
                                  <JobErButton
                                    onClick={() =>

                                    }
                                    label=
                                  >
                                   {"Visualizza"}
                                  </JobErButton>
                                </Grid> */}
                                </Grid>
                              }
                            />
                          </ListItem>
                          {index < aziendeTerritorio?.length - 1 ? <Divider variant="inset" component="li" key={"divider_" + index} /> : null}
                        </React.Fragment>
                      ))}
                    </List>
                    <Grid container justifyContent="center" mt={2}>
                      <Pagination
                        count={pagination.totalPages}
                        sx={{
                          "& .MuiPaginationItem-root": {
                            color: "rgb(0, 43, 72) !Important",
                          },
                          "& .Mui-selected": {
                            backgroundColor: "rgb(0, 43, 72) !Important",
                            color: "white !Important",
                          },
                        }}
                        variant="outlined"
                        defaultPage={page + 1}
                        onChange={(e, value) => {
                          setPage(value - 1);
                        }}
                      />
                    </Grid>
                  </>
                ) : null}
                {loading === true && provinciaSelezionata !== null && aziendeTerritorio?.length === 0 && (
                  <Grid container justifyContent="center" mt={4}>
                    <Grid
                      size={12}
                      sx={{
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <CardAziendeHomepageDettaglio open={open} azienda={aziendaSelezionata} setOpen={setOpen} />
        </Grid>
      }
    />
  );
}
export default CardAziendeHomepage;
