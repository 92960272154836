import React, { useState } from "react";
import JobErCard from "components/JobErCard";
import { Divider, Grid2 as Grid, Typography, TextField } from "@mui/material";
import { asyncRicercaComuni } from "utils/utilsRicerca";
import options from "../../json/option_configuration.json";
import { dataNascitaDaCodiceFiscale } from "utils/utilsDati";
import JobErButton from "components/JobErButton";
import JobErAutocomplete from "components/JobErAutocomplete";

function CardProfiloDatiAnagrafici(props) {
  const [optionComuni, setOptionComuni] = useState([]);

  return (
    <JobErCard
      title="Dati Anagrafici"
      style={{ minHeight: "300px" }}
      child={
        <Grid container justifyContent="center" spacing={2} mt={1}>
          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              fullWidth
              label="Nome"
              size="small"
              value={props.dati?.nome || props.authUser?.nome || null}
              onChange={(e) => props.onSetDati("nome", e.target.value)}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              fullWidth
              label="Cognome"
              size="small"
              value={props.dati?.cognome || props.authUser?.cognome || null}
              onChange={(e) => props.onSetDati("cognome", e.target.value)}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <TextField
              fullWidth
              label="Codice Fiscale"
              size="small"
              disabled={true}
              value={props.authUser?.codiceFiscale || props.dati?.codiceFiscale || null}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <TextField
              fullWidth
              label="Data Nascita"
              disabled={true}
              size="small"
              value={
                props.authUser?.codiceFiscale
                  ? dataNascitaDaCodiceFiscale(props.authUser.codiceFiscale)
                  : props.dati?.codiceFiscale
                  ? dataNascitaDaCodiceFiscale(props.dati.codiceFiscale)
                  : null
              }
            />
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <TextField fullWidth size="small" label="Email" disabled={true} value={props.authUser?.email || props.dati?.email || null} />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <TextField
              fullWidth
              size="small"
              label="Telefono"
              value={props.dati?.telefono || null}
              onChange={(e) => props.onSetDati("telefono", e.target.value)}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <JobErAutocomplete
              size="small"
              label="Ricerca Comune"
              value={
                props.dati?.comuneResidenza
                  ? {
                      value: props.dati.comuneResidenza,
                      label: props.dati.comuneResidenza,
                    }
                  : null
              }
              onInput={(e) => {
                let inputValue = e.target.value;
                if (inputValue?.length >= 3) {
                  asyncRicercaComuni(inputValue).then((response) => {
                    if (response) {
                      setOptionComuni(response);
                    }
                  });
                }
              }}
              options={optionComuni}
              onChange={(data, newValue) => {
                props.onSetDati("comuneResidenza", newValue ? newValue.label : null);
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              fullWidth
              size="small"
              label="Indirizzo Residenza"
              value={props.dati?.indirizzo || null}
              onChange={(e) => props.onSetDati("indirizzo", e.target.value)}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <JobErAutocomplete
              size="small"
              label="Stato civile"
              options={options.stato_civile}
              value={props.dati?.statoCivile ? options.stato_civile.find((opt) => opt.value === props.dati.statoCivile) : null}
              onChange={(event, newValue) => {
                props.onSetDati("statoCivile", newValue ? newValue.value : null);
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <JobErAutocomplete
              size="small"
              label="Patente"
              options={options.patenti}
              multiple
              value={props.dati?.patenti ? options.patenti.filter((opt) => props.dati.patenti.split(",").includes(opt.value)) : []}
              onChange={(event, newValue) => {
                const parseData = newValue.map((patente) => patente.value);
                props.onSetDati("patenti", parseData.join(","));
              }}
            />
          </Grid>
          <Grid size={12} m={1.5}>
            <Divider />
          </Grid>
          <Grid size={12} md={12}>
            <Typography variant="p" color="error" fontWeight="bold">
              *Solo per lavoratori stranieri
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <JobErAutocomplete
              size="small"
              label="Permesso soggiorno"
              value={props.dati?.permessoSoggiorno ? options.permesso_soggiorno.find((opt) => opt.value === props.dati.permessoSoggiorno) : null}
              options={options.permesso_soggiorno}
              onChange={(data, newValue) => {
                props.onSetDati("permessoSoggiorno", newValue ? newValue.value : null);
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              fullWidth
              size="small"
              label="Data Scadenza"
              type="date"
              value={props.dati?.dataScadenzaPermessoSoggiorno ? props.dati.dataScadenzaPermessoSoggiorno : null}
              onChange={(e) => props.onSetDati("dataScadenzaPermessoSoggiorno", e.target.value)}
            />
          </Grid>
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12} mt={2}>
            <Grid container justifyContent="center">
              <Grid size="auto">
                <JobErButton size="small" color={"success"} onClick={() => props.onSave()}>
                  {"Salva"}
                </JobErButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloDatiAnagrafici;
