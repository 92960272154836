import { JobErToast } from "components/JobErToast";

export function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const MESI = {
  A: "01",
  B: "02",
  C: "03",
  D: "04",
  E: "05",
  H: "06",
  L: "07",
  M: "08",
  P: "09",
  R: "10",
  S: "11",
  T: "12",
};

export function dataNascitaDaCodiceFiscale(codiceFiscale) {
  if (codiceFiscale) {
    let [anno, giorno] = [codiceFiscale.substring(6, 8), codiceFiscale.substring(9, 11)];
    if (giorno > 40) {
      giorno -= 40;

      if (giorno.length === 1) {
        giorno = "0" + giorno;
      }
    }
    return giorno + "/" + MESI[codiceFiscale.charAt(8)] + "/" + (anno < 20 ? "20" : "19") + anno;
  }
}

export function decodeHTMLEntities(testo) {
  let div = document.createElement("div");
  div.innerHTML = testo;
  return div.textContent || div.innerText || "";
}

export function convetTimestampToDateIta(isoString) {
  if (isoString) {
    const date = new Date(isoString);

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  }
}

export function convertDateFormatIta(dateStr) {
  let today = new Date(dateStr);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "-" + mm + "-" + yyyy;

  return formattedToday;
}

export function convertDateFormatUsa(dateStr) {
  let today = new Date(dateStr);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = yyyy + "-" + mm + "-" + dd;

  return formattedToday;
}

export function downloadDocumento(doc, nomeFile) {
  if (doc && doc.includes("base64,")) {
    let splitFile = doc.split(";base64,");
    let file = splitFile[1];

    let splitTipoFile = splitFile[0].split(":");
    let tipoFile = splitTipoFile[1];

    let splitFormato = tipoFile.split("/");
    let formato = splitFormato[1];

    const byteCharacters = atob(file);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: tipoFile ? tipoFile.toString() : "application/octet-stream" });

    // Create a Blob URL
    const url = URL.createObjectURL(blob);

    // Create a downloadable link
    const link = document.createElement("a");
    link.href = url;
    link.download = nomeFile + "." + formato;

    // Trigger a click event on the link to initiate the download
    link.click();

    // Clean up by revoking the Blob URL
    URL.revokeObjectURL(url);
  } else {
    JobErToast.fire({ icon: "error", title: "Non è possibile scaricare il documento" });
  }
}
