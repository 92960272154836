import React from "react";
import { Typography, Grid2 as Grid, TextField, useTheme } from "@mui/material";
import { ReactComponent as LOGO_CONFARTIGIANATO } from "../../../immagini/JOB_LOGO_CONFER_BLUE.svg";

import JobErButton from "components/JobErButton";

function LoginAzienda(props) {
  const theme = useTheme();
  const checkEmail = (mail) => {
    const path = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (path.test(mail) && mail) {
      props.setErrori({ ...props.errori, username: "ok" });
    } else {
      props.setErrori({ ...props.errori, username: "invalid" });
    }
  };

  const checkPassword = (password) => {
    if (password) {
      props.setErrori({ ...props.errori, password: "ok" });
    } else {
      props.setErrori({ ...props.errori, password: "invalid" });
    }
  };

  const checkPiva = (piva) => {
    if (piva && piva.length === 11) {
      props.setErrori({ ...props.errori, piva: "ok" });
    } else {
      props.setErrori({ ...props.errori, piva: "invalid" });
    }
  };

  return (
    <Grid container style={{ padding: 30, paddingTop: 0 }}>
      {!props.noimage ? (
        <Grid size={12} textAlign="center" mb={2}>
          {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
            <img
              alt="logo"
              src={require("../../../immagini/MyLapamJOB_Logo.png")}
              style={{
                height: "70px",
                width: "280px",
                borderRadius: 15,
                marginBottom: "20px",
              }}
            />
          ) : (
            <LOGO_CONFARTIGIANATO className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_lg"></LOGO_CONFARTIGIANATO>
          )}
        </Grid>
      ) : null}
      <Grid size={12} textAlign="center">
        <Typography variant="h6" fontWeight="bold" color={theme.palette.primary.main}>
          {props.passwordDimenticata ? "PASSWORD DIMENTICATA AZIENDA" : "ACCESSO AZIENDA"}
        </Typography>
      </Grid>
      {props.statoAccesso === "credenziali" && (
        <>
          <Grid size={12} mt={2}>
            <TextField
              fullWidth
              label="Email"
              size="small"
              value={props.datiLogin?.username || ""}
              onChange={(e) => props.setDatiLogin({ ...props.datiLogin, username: e.target.value })}
              onBlur={(e) => checkEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (props.passwordDimenticata) {
                    props.reimpostaPassword();
                  } else {
                    props.login();
                  }
                }
              }}
            />
          </Grid>
          {props.passwordDimenticata ? (
            <>
              <Grid size={12} mt={2}>
                <TextField
                  fullWidth
                  label="Partita Iva"
                  size="small"
                  value={props.datiLogin?.piva || ""}
                  onChange={(e) => props.setDatiLogin({ ...props.datiLogin, piva: e.target.value })}
                  onBlur={(e) => checkPiva(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      props.reimpostaPassword();
                    }
                  }}
                />
              </Grid>
              <Grid size={12} mt={2} textAlign="center">
                <JobErButton
                  onClick={() => {
                    props.setPasswordDimenticata(false);
                  }}
                >
                  Torna al login
                </JobErButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid size={12} mt={2}>
                <TextField
                  fullWidth
                  label="Password"
                  size="small"
                  value={props.datiLogin?.password || ""}
                  type="password"
                  onChange={(e) => props.setDatiLogin({ ...props.datiLogin, password: e.target.value })}
                  onBlur={(e) => checkPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      props.login();
                    }
                  }}
                />
              </Grid>
              <Grid size={12} mt={2} textAlign="center">
                <JobErButton
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    props.setPasswordDimenticata(true);
                  }}
                >
                  Password dimenticata?
                </JobErButton>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}
export default LoginAzienda;
