import React, { useState } from "react";
import JobErCard from "components/JobErCard";
import { Divider, Grid2 as Grid, TextField } from "@mui/material";
import { asyncRicercaComuni, asyncRicercaRuoli } from "utils/utilsRicerca";
import options from "../../json/option_configuration.json";
import JobErButton from "components/JobErButton";
import Swal from "sweetalert2";
import JobErAutocomplete from "components/JobErAutocomplete";

function CardProfiloLavoriInteresse(props) {
  const [optionRuoli, setOptionRuoli] = useState([]);
  const [optionComuni, setOptionComuni] = useState([]);
  const [loading, setLoading] = useState(false);
  const alertDeleteLavoroInteresse = (uuid) => {
    Swal.fire({
      text: "Sei sicuro di voler cancellare questo interesse?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.onRemoveNestedData(uuid);
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <JobErCard
      title="Lavori di Interesse"
      style={{ minHeight: "300px" }}
      child={
        <Grid container justifyContent="center" spacing={2} mt={1}>
          {props.dati.map((interesse, index) => (
            <React.Fragment key={index}>
              <Grid size={{ xs: 12, md: 6 }}>
                <JobErAutocomplete
                  onClose={() => {
                    setOptionRuoli([]);
                  }}
                  loading={loading}
                  size="small"
                  label="Ruolo"
                  value={
                    interesse?.ruolo
                      ? {
                          value: interesse.ruolo,
                          label: interesse.ruolo,
                        }
                      : null
                  }
                  onInput={(e) => {
                    let inputValue = e.target.value;

                    if (inputValue?.length >= 3) {
                      setLoading(true);
                      asyncRicercaRuoli(inputValue).then((response) => {
                        if (response) {
                          setOptionRuoli(response);
                          setLoading(false);
                        }
                      });
                    }
                  }}
                  options={optionRuoli}
                  onChange={(data, newValue) => {
                    props.onSetNestedDati(interesse.uuid, "ruolo", newValue ? newValue.label : null);
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <JobErAutocomplete
                  onClose={() => {
                    setOptionComuni([]);
                  }}
                  loading={loading}
                  size="small"
                  label="Comune"
                  value={
                    interesse?.comune
                      ? {
                          value: interesse.comune,
                          label: interesse.comune,
                        }
                      : null
                  }
                  onInput={(e) => {
                    let inputValue = e.target.value;

                    if (inputValue?.length >= 3) {
                      setLoading(true);
                      asyncRicercaComuni(inputValue).then((response) => {
                        if (response) {
                          setOptionComuni(response);
                          setLoading(false);
                        }
                      });
                    }
                  }}
                  options={optionComuni}
                  onChange={(data, newValue) => {
                    props.onSetNestedDati(interesse.uuid, "comune", newValue ? newValue.label : null);
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <JobErAutocomplete
                  size="small"
                  multiple
                  label="Orari Lavoro"
                  options={options.turni_lavoro}
                  value={interesse?.orarioLavorativo ? options.turni_lavoro.filter((opt) => interesse.orarioLavorativo.split(",").includes(opt.value)) : []}
                  onChange={(event, newValue) => {
                    const parseData = newValue.map((orarioLavorativo) => orarioLavorativo.value);
                    props.onSetNestedDati(interesse.uuid, "orarioLavorativo", parseData.join(","));
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <JobErAutocomplete
                  size="small"
                  label="Tipologia contratto"
                  options={options.tipologie_contratto}
                  value={
                    interesse?.tipologiaContratto
                      ? options.tipologie_contratto.find((opt) => opt.label === interesse.tipologiaContratto || opt.value === interesse.tipologiaContratto)
                      : null
                  }
                  onChange={(event, newValue) => {
                    props.onSetNestedDati(interesse.uuid, "tipologiaContratto", newValue?.label ? newValue.label : null);
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label="Stipendio minimo"
                  size="small"
                  value={interesse?.stipendioMinimo ? interesse.stipendioMinimo : ""}
                  onChange={(e) => props.onSetNestedDati(interesse.uuid, "stipendioMinimo", e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label="RAL minima"
                  size="small"
                  value={interesse?.ralMinima ? interesse.ralMinima : ""}
                  onChange={(e) => props.onSetNestedDati(interesse.uuid, "ralMinima", e.target.value)}
                />
              </Grid>

              {index > 0 && (
                <Grid size={12}>
                  <JobErButton size="small" color="error" onClick={() => alertDeleteLavoroInteresse(interesse.uuid)}>
                    Rimuovi
                  </JobErButton>
                </Grid>
              )}
              <Grid size={12} m={1.5}>
                <Divider />
              </Grid>
            </React.Fragment>
          ))}
          <Grid size={12} mt={2}>
            <Grid container justifyContent="center">
              <Grid size="auto">
                <JobErButton size="small" color="success" onClick={() => props.onSave()}>
                  Salva
                </JobErButton>
                <JobErButton size="small" onClick={() => props.onAddNestedObject()}>
                  Aggiungi Lavoro Interesse
                </JobErButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloLavoriInteresse;
