import React, { useEffect, useState } from "react";
import { Grid2 as Grid, Typography, Radio, FormControl, RadioGroup, FormControlLabel, TextField, useTheme } from "@mui/material";
import JobErButton from "components/JobErButton";
import {
  completaRegistrazioneAzienda,
  verificaCampiRegistrazione,
  verificaAziendaUtenteMailRegistrazione,
  verificaPasswordRegistrazione,
  verificaAziendaUtentePivaRegistrazione,
} from "utils/utilsLogin";
import { asyncRicercaComuni, asyncRicercaSettore, ricercaConfartigiananto } from "utils/utilsRicerca";
import JobErAutocomplete from "components/JobErAutocomplete";

function RegistrazioneAzienda(props) {
  const theme = useTheme();
  const [formData, setFormData] = useState({});
  const [dataError, setDataError] = useState([]);
  const [verificaPassword, setVerificaPassword] = useState(null);
  const [verificaEmail, setVerificaEmail] = useState(null);
  const [verificaPiva, setVerificaPiva] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listaConfartigianato, setListaConfartigianato] = useState([]);
  const [optionComuni, setOptionComuni] = useState([]);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    ricercaConfartigiananto(setListaConfartigianato);
  }, []);

  return (
    <>
      <Grid container justifyContent="center" spacing={1}>
        <Grid size={12} mt={3}>
          <Typography variant="h7" color={theme.palette.white.main} sx={{ textAlign: "center", fontWeight: "bold" }}>
            Dati utente
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Email"
            size="small"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            onBlur={(e) => verificaAziendaUtenteMailRegistrazione(e.target.value, setVerificaEmail)}
            helperText={verificaEmail === "forbidden" ? "Email già utilizzata" : verificaEmail === "invalid" ? "Email non valida" : "Inserisci la tua email"}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Password"
            size="small"
            helperText={verificaPassword !== "ok" && verificaPassword ? verificaPassword : "Inserisci la password"}
            value={formData.password || ""}
            type="password"
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            onBlur={(e) => verificaPasswordRegistrazione(e.target.value, setVerificaPassword)}
            error={verificaPassword !== "ok" && verificaPassword}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Ripeti Password"
            size="small"
            type="password"
            helperText="Inserisci nuovamente la password"
            value={formData.ripetiPassword || ""}
            onChange={(e) => setFormData({ ...formData, ripetiPassword: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={1}>
        <Grid size={12} mt={3}>
          <Typography variant="h7" color="white" fontWeight="bold" sx={{ textAlign: "center" }}>
            Dati azienda
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            color="white"
            fullWidth
            label="Ragione Sociale"
            size="small"
            helperText="Inserisci la ragione sociale"
            value={formData.ragioneSociale || ""}
            onChange={(e) => setFormData({ ...formData, ragioneSociale: e.target.value })}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Partita IVA"
            size="small"
            value={formData.partitaIva || ""}
            onChange={(e) => setFormData({ ...formData, partitaIva: e.target.value })}
            helperText={verificaPiva === "forbidden" ? "Piva già utilizzata" : verificaPiva === "invalid" ? "Piva non valida" : "Inserisci la tua Piva"}
            onBlur={(e) => verificaAziendaUtentePivaRegistrazione(e.target.value, setVerificaPiva)}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Codice Fiscale"
            size="small"
            helperText="Inserisci il tuo codice fiscale"
            value={formData.codiceFiscale || ""}
            onChange={(e) => setFormData({ ...formData, codiceFiscale: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" mt={1} spacing={1}>
        <Grid size={{ xs: 12, md: 4 }}>
          <JobErAutocomplete
            color="white"
            error={dataError.includes("comune")}
            onClose={() => {
              setOptionComuni([]);
            }}
            loading={loading2}
            size="small"
            label="Ricerca Comune"
            value={formData.comune}
            onInput={(e) => {
              let inputValue = e.target.value;

              if (inputValue?.length >= 3) {
                setLoading2(true);
                asyncRicercaComuni(inputValue).then((response) => {
                  if (response) {
                    setOptionComuni(response);
                    setLoading2(false);
                  }
                });
              }
            }}
            options={optionComuni}
            onChange={(data, newValue) => {
              setFormData({ ...formData, comune: newValue });
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Indirizzo"
            size="small"
            helperText="Inserisci l'indirizzo"
            value={formData.indirizzo || ""}
            onChange={(e) => setFormData({ ...formData, indirizzo: e.target.value })}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 4 }}>
          <JobErAutocomplete
            error={dataError.includes("settore")}
            onClose={() => {
              setOptionComuni([]);
            }}
            color="white"
            loading={loading2}
            size="small"
            label="Ricerca Settore"
            value={formData.settore}
            onInput={(e) => {
              let inputValue = e.target.value;

              if (inputValue?.length >= 3) {
                setLoading2(true);
                asyncRicercaSettore(inputValue).then((response) => {
                  if (response) {
                    setOptionComuni(response);
                    setLoading2(false);
                  }
                });
              }
            }}
            options={optionComuni}
            onChange={(data, newValue) => {
              setFormData({ ...formData, settore: newValue });
            }}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" mt={1} spacing={1}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Grid container>
            <Grid size={12}>
              <Typography
                color="white"
                variant="h6"
                sx={{
                  textAlign: "center!important",
                }}
              >
                Sei già associato ad una confartigianato?
              </Typography>
            </Grid>
            <Grid size={12}>
              <FormControl>
                <RadioGroup row onChange={(e) => setFormData({ ...formData, associato: e.target.value })}>
                  <FormControlLabel
                    color="white"
                    value="si"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: dataError.includes("associato") ? theme.palette.error.main : theme.palette.success.main,
                          },
                          "&": {
                            color: dataError.includes("associato") ? theme.palette.error.main : theme.palette.white.main,
                          },
                        }}
                      />
                    }
                    label="Sì"
                    style={{ color: theme.palette.white.main }}
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: dataError.includes("associato") ? theme.palette.error.main : theme.palette.success.main,
                          },
                          "&": {
                            color: dataError.includes("associato") ? theme.palette.error.main : theme.palette.white.main,
                          },
                        }}
                      />
                    }
                    label="No"
                    style={{ color: theme.palette.white.main }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {formData.associato === "si" && (
          <Grid size={{ xs: 12, md: 3 }}>
            <JobErAutocomplete
              color="white"
              size="small"
              label="Ricerca Confartigianato *"
              error={dataError.includes("provConfartigianato")}
              value={formData.provConfartigianato}
              options={listaConfartigianato}
              onChange={(event, newValue) => {
                setFormData({ ...formData, provConfartigianato: newValue });
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="center" mt={1} spacing={1}>
        <Grid size="auto">
          <JobErButton
            color="white"
            variant="outlined"
            isLoading={loading}
            onClick={() => {
              if (verificaCampiRegistrazione("azienda", formData, setDataError)) {
                if (verificaEmail === "ok" && verificaPiva === "ok") {
                  completaRegistrazioneAzienda(formData, props.onRegistrazioneCompletata, setLoading);
                }
              }
            }}
          >
            Completa Registrazione
          </JobErButton>
        </Grid>
      </Grid>
    </>
  );
}
export default RegistrazioneAzienda;
