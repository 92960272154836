const outlinedInput = {
  defaultProps: {
    disableInjectingGlobalStyles: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      borderRadius: "8px",
      fontSize: `16px !important`,
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette[ownerState.color].main,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette[ownerState.color].main,
      },
    }),
    input: ({ ownerState, theme }) => ({
      padding: `8px 13px`,
      borderRadius: "8px",
      color: theme.palette[ownerState.color].main,
    }),
  },
};

export default outlinedInput;
