import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider, useTheme } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import FactoryIcon from "@mui/icons-material/Factory";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import { LockOpen } from "@mui/icons-material";
import { ModalContext } from "context/modalLogin";

function MenuPortableDevice(props) {
  const theme = useTheme();
  const { setIsModalOpen } = useContext(ModalContext);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <IconButton size="large" aria-label="" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiPaper-root": {
            borderTopRightRadius: "20px!important",
            borderBottomRightRadius: "20px!important",
            borderBottomLeftRadius: "20px!important",
            width: "90%",
          },
        }}
      >
        {props.pages.map((page, index) => {
          return (
            <MenuItem key={page.url + "_xs"} onClick={() => navigate("/" + page.url.replaceAll(" ", ""))} style={{ justifyContent: "center" }}>
              {page.url === "Vetrina/Home" ? (
                <HomeIcon sx={{ color: theme.palette.primary.main, marginRight: 1 }} />
              ) : page.url === "Vetrina/Persone" ? (
                <PeopleIcon sx={{ color: theme.palette.primary.main, marginRight: 1 }} />
              ) : page.url === "Vetrina/Aziende" ? (
                <FactoryIcon
                  sx={{
                    color: theme.palette.primary.main,
                    marginRight: 1,
                  }}
                />
              ) : null}
              <Typography
                component="p"
                sx={{
                  color: theme.palette.primary.main,
                }}
              >
                {page.descrizione}
              </Typography>
            </MenuItem>
          );
        })}
        <Divider />
        <MenuItem key={"registrati_xs"} onClick={() => navigate("/Vetrina/Registrati")} style={{ justifyContent: "center" }}>
          <AddIcon sx={{ color: theme.palette.primary.main, marginRight: 1 }} />
          <Typography
            component="p"
            sx={{
              color: theme.palette.primary.main,
            }}
          >
            Registrati
          </Typography>
        </MenuItem>
        <MenuItem
          key={"accedi_xs"}
          onClick={() => {
            setIsModalOpen(true);
          }}
          style={{ justifyContent: "center" }}
        >
          <LockOpen sx={{ color: theme.palette.primary.main, marginRight: 1 }} />
          <Typography
            component="p"
            sx={{
              color: theme.palette.primary.main,
            }}
          >
            Accedi
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
export default MenuPortableDevice;
