import React from "react";
import { Avatar, useTheme } from "@mui/material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";

function StatusAvatar({ stato }) {
  const theme = useTheme();
  let avatarIcon = null;
  let backgroundColor = "";

  switch (stato) {
    case 1:
      avatarIcon = <CheckIcon />;
      backgroundColor = theme.palette.success.main;
      break;
    case 3:
      avatarIcon = <HourglassBottomIcon />;
      backgroundColor = theme.palette.warning.main;
      break;
    case 4:
      avatarIcon = <ClearIcon />;
      backgroundColor = theme.palette.error.main;
      break;
    default:
      break;
  }

  if (avatarIcon) {
    return <Avatar style={{ backgroundColor }}>{avatarIcon}</Avatar>;
  } else {
    return null;
  }
}

StatusAvatar.propTypes = {
  stato: PropTypes.number,
};

export default StatusAvatar;
