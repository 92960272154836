import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button, Container } from "@mui/material";
import { ReactComponent as BB_LONG_ER } from "../../immagini/JOB_LOGO_CONFER_WHITE.svg";
import { ReactComponent as BB_LONG_MORE } from "../../immagini/Logo_MylapamJob_WHITE.svg";
import { useNavigate } from "react-router-dom";
import MenuPortableDevice from "./MenuPortableDevice";
import { ModalContext } from "context/modalLogin";
import PopoverLogin from "./Login/PopoverLogin";
import JobErButton from "components/JobErButton";

const pages = [
  { descrizione: "Home", url: "Vetrina/Home" },
  { descrizione: "Per i candidati", url: "Vetrina/Persone" },
  { descrizione: "Per le aziende", url: "Vetrina/Aziende" },
  { descrizione: "Chi siamo", url: "Vetrina/ChiSiamo" },
  { descrizione: "Contattaci", url: "Vetrina/Contattaci" },
];

function AppBarJob(props) {
  const { setIsModalOpen } = useContext(ModalContext);

  const colorText = props.color;
  const bgcolor = props.bgcolor;

  const navigate = useNavigate();

  return (
    <AppBar position="static" sx={{ backgroundColor: bgcolor, pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 }, pb: { xs: 2, md: 5 } }}>
      <Container maxWidth={true}>
        <Toolbar disableGutters>
          {/*icon desktop*/}
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 4 }}>
            {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
              <BB_LONG_MORE
                fill={colorText}
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_xxl bb_menu"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/Vetrina/Home")}
              ></BB_LONG_MORE>
            ) : (
              <BB_LONG_ER
                fill={colorText}
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_xxl bb_menu"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/Vetrina/Home")}
              ></BB_LONG_ER>
            )}
          </Box>
          {/*icon smartphone*/}
          <Box sx={{ display: { xs: "flex", md: "none" }, mr: 2 }}>
            {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
              <BB_LONG_MORE
                fill={colorText}
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_xxl bb_menu"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/Vetrina/Home")}
              ></BB_LONG_MORE>
            ) : (
              <BB_LONG_ER
                fill={colorText}
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_xxl bb_menu"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/Vetrina/Home")}
              ></BB_LONG_ER>
            )}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, justifyContent: "center" }}>
            <MenuPortableDevice pages={pages} />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, index) => {
              return page.url !== "Vetrina/Home" ? (
                <Button sx={{ mr: 3 }} key={index} onClick={() => navigate("/" + page.url.replaceAll(" ", ""))}>
                  <Typography color="white.main">{page.descrizione}</Typography>
                </Button>
              ) : null;
            })}
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <JobErButton size="large" variant="contained" color="white" onClick={() => navigate("/Vetrina/Registrati")}>
              Registrati
            </JobErButton>

            <JobErButton
              color="white"
              size="large"
              variant="outlined"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Accedi
            </JobErButton>
          </Box>
        </Toolbar>
      </Container>
      <PopoverLogin />
    </AppBar>
  );
}
export default AppBarJob;
