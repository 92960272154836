import React, { useContext } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import SliderWrapper from "./_SlickSliderStyle";
import { useNavigate } from "react-router-dom";
import CardAnnunciPersone from "./CardAnnunciPersone";
import JobErButton from "components/JobErButton";
import CircularProgress from "@mui/material/CircularProgress";
import { ModalContext } from "context/modalLogin";

function PresentazioneServizio(props) {
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <Grid container spacing={3} backgroundColor={"primary.main"}>
      {props.loading ? (
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {props.annunciFiltrati.length === 0 && (
            <Grid size={12} pb={10}>
              <SliderWrapper>
                <Slider
                  dots={true}
                  fade={true}
                  infinite={true}
                  speed={500}
                  slidesToShow={1}
                  slidesToScroll={1}
                  waitForAnimate={true}
                  arrows={false}
                  swipe={true}
                  autoplay={true}
                  autoplaySpeed={2500}
                  centerMode={true}
                  appendDots={(dots) => <ul>{dots}</ul>}
                  customPaging={(i) => (
                    <div className="ft-slick__dots--custom">
                      <div className="loading" />
                    </div>
                  )}
                >
                  {props.annunci?.length > 0 &&
                    props.annunci
                      .reduce((acc, _, i) => (i % 4 === 0 ? [...acc, props.annunci.slice(i, i + 4)] : acc), [])
                      .map((chunk, rowIndex) => (
                        <Grid
                          sx={{
                            display: "flex !important",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          container
                          spacing={2}
                          key={`row_${rowIndex}`}
                        >
                          {chunk.map((annuncio, index) => (
                            <Grid size={{ xs: 12, md: 3 }} key={`annuncio_${rowIndex}_${index}`}>
                              <CardAnnunciPersone
                                gruppoLavoro={annuncio?.posizioneLavorativa?.gruppo || null}
                                mansione={annuncio?.posizioneLavorativa?.descrizione || null}
                                titolo={annuncio?.titoloAnnuncio || null}
                                descrizioneLavoro={annuncio?.descrizioneLavoro || null}
                                comune={annuncio?.comune?.des || null}
                                posizioniAperte={annuncio?.posizioniAperte || null}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      ))}
                </Slider>
              </SliderWrapper>
            </Grid>
          )}
        </>
      )}
      <Grid size={12}>
        {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
          <>
            <Grid container spacing={3}>
              <Grid size={12}>
                <Typography variant="h1" color="white.main" sx={{ textAlign: "center!important" }}>
                  Cerchi lavoro? Non perdere tempo, affidati a noi!
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid size={12}>
                <Typography variant="h5" color="white.main" sx={{ textAlign: "left" }} mt={2}>
                  Mylapam job mette a disposizione per i candidati in forma totalmente gratuita, il proprio servizio, la propria professionalità e il proprio
                  supporto affinché si possa andare insieme con fiducia verso una nuova sfida e un nuovo inizio.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid size={12}>
                <Typography variant="h5" color="white.main" sx={{ textAlign: "left" }} mt={2}>
                  Nuove esperienze professionali, nuovi inserimenti e nuove crescite: gli annunci di lavoro pubblicati sulla nostra piattaforma sono rivolti sia
                  a chi cerca un primo lavoro sia a chi cerca un nuovo lavoro.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid size={12}>
                <Typography variant="h5" color="white.main" sx={{ textAlign: "left" }} mt={2}>
                  L’inserimento ti permetterà di essere:
                  <ul>
                    <li>contattato dai nostri consulenti MyLapam Job;</li>
                    <li>proposto per tutte le ricerche di personale che risultino compatibili con i propri dati personali e professionali;</li>
                    <li>
                      convocato per un colloquio conoscitivo presso la nostra sede di Modena o attraverso un video colloquio nel caso tu non abbia la
                      possibilità di raggiungerci.
                    </li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container>
              <Grid size={12}>
                <Typography variant="h1" color="white.main" sx={{ textAlign: "left!important" }}>
                  Cerchi lavoro? Non perdere tempo, affidati a noi!
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid size={12}>
                <Typography variant="h5" color="white.main" sx={{ textAlign: "left" }} mt={2}>
                  Job Confartigianato mette a disposizione per i candidati in forma totalmente gratuita, il proprio servizio, la propria professionalità e il
                  proprio supporto affinché si possa andare insieme con fiducia verso una nuova sfida e un nuovo inizio.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid size={12}>
                <Typography variant="h5" color="white.main" sx={{ textAlign: "left" }} mt={2}>
                  Nuove esperienze professionali, nuovi inserimenti e nuove crescite: gli annunci di lavoro pubblicati sulla nostra piattaforma sono rivolti sia
                  a chi cerca un primo lavoro sia a chi cerca un nuovo lavoro.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid size={12}>
                <Typography variant="h5" color="white.main" sx={{ textAlign: "left" }} mt={2}>
                  L’inserimento ti permetterà di essere:
                  <ul>
                    <li>contattato dai nostri consulenti Job Confartigianato;</li>
                    <li>proposto per tutte le ricerche di personale che risultino compatibili con i propri dati personali e professionali;</li>
                    <li>
                      convocato per un colloquio conoscitivo presso la nostra sede di Modena o attraverso un video colloquio nel caso tu non abbia la
                      possibilità di raggiungerci.
                    </li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        <Grid container mt={1} spacing={2}>
          <Grid size="auto">
            <JobErButton color="white" size="large" onClick={() => navigate("/Vetrina/Registrati")}>
              Registrati
            </JobErButton>
          </Grid>
          <Grid size="auto">
            <JobErButton
              size="large"
              variant="outlined"
              color="white"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Accedi
            </JobErButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default PresentazioneServizio;
