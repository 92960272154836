import React, { useState, useEffect } from "react";
import { Grid2 as Grid } from "@mui/material";
import FooterBB from "components/Vetrina/Footer";
import PresentazioneServizio from "components/Vetrina/PerLePersone/PresentazioneServizio";
import AppBarJob from "components/Vetrina/AppBarJob";
import RicercaLavoriPersona from "./RicercaLavoriPersona";
import { JobErToast } from "components/JobErToast";

function Persone(props) {
  const [ultimiAnnunci, setUltimiAnnunci] = useState([]);
  const [annunciFiltrati, setAnnunciFiltrati] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    fetch(process.env.REACT_APP_URL + "/vetrina/elencoAnnunci", {
      method: "GET",
    })
      .then((res) => {
        if (!res.ok) {
          JobErToast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
          return null;
        }
        return res.json();
      })
      .then(
        (result) => {
          let arrayUltimiAnnunci = result.slice(0, 12);
          setUltimiAnnunci(arrayUltimiAnnunci);
          setLoading(false);
        },
        (error) => {
          console.error("Si è verificato un errore nel caricamento degli annunci");
          JobErToast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento degli annunci" });
        }
      );

    setLoading(false);
  }, []);

  return (
    <>
      <AppBarJob bgcolor={"primary.main"} color={"white.main"} activePage={"Persone"} onUserLogin={(user) => props.onUserLogin(user)}></AppBarJob>
      <Grid container spacing={3} backgroundColor={"primary.main"} sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 } }} pb={10}>
        <RicercaLavoriPersona annunci={annunciFiltrati} setAnnunci={setAnnunciFiltrati} />
        <PresentazioneServizio annunci={ultimiAnnunci} loading={loading} annunciFiltrati={annunciFiltrati} />
      </Grid>
      <FooterBB color1={"grey.light"} color2={"primary.light"} color3="white" />
    </>
  );
}
export default Persone;
