import React from "react";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Grid2 as Grid, Avatar, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import JobErButton from "components/JobErButton";
import { useJobErController } from "context";

function MenuAvatarPortableDevice(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [controller] = useJobErController();
  const { authUser } = controller;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <Avatar alt="profilo" sx={{ width: 40, height: 40 }} onClick={handleOpenNavMenu}>
        {authUser?.cognome ? authUser.cognome.substring(0, 2) : null}
      </Avatar>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiPaper-root": {
            borderTopRightRadius: "20px!important",
            borderBottomRightRadius: "20px!important",
            borderBottomLeftRadius: "20px!important",
            width: "70%",
          },
        }}
      >
        <MenuItem key={"headerlogin_menu_xs"}>
          <Grid container justifyContent="center">
            <Grid size={12} textAlign="center">
              <Typography
                variant="h4"
                color={theme.palette.primary.main}
                fontWeight="bold"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {authUser.nome + " " + authUser.cognome}
              </Typography>
            </Grid>

            <Grid size={12} textAlign="center" mt={1}>
              <JobErButton
                color="error"
                onClick={() => {
                  localStorage.removeItem("accessTokenJob");
                  navigate("/Vetrina/Home");
                }}
              >
                Logout
              </JobErButton>
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  );
}
export default MenuAvatarPortableDevice;
