import React from "react";
import { Grid2 as Grid, Typography, List, Divider, useTheme } from "@mui/material";
import ViewCandidatura from "./ViewCandidatura";

function ListaCandidature(props) {
  const theme = useTheme();
  return (
    <Grid container mt={1} mb={2} spacing={2} justifyContent="center">
      {props.candidature ? (
        <Grid size={12}>
          <Typography variant="h7" fontWeight="bold" style={{ color: theme.palette.primary.main }}>
            Candidature trovate: {props.candidature.length}
          </Typography>
        </Grid>
      ) : null}
      <Grid size={12}>
        {props.candidature ? (
          <List>
            {props.candidature.map((candidatura, index) => (
              <React.Fragment key={"view_" + index}>
                <ViewCandidatura
                  candidature={props.candidature}
                  setCandidature={props.setCandidature}
                  candidatura={candidatura}
                  index={index}
                  searchParams={props.searchParams}
                />
                {index < props.candidature.length - 1 && (
                  <>
                    <br></br>
                    <Divider key={"divider_" + index} />
                    <br></br>
                  </>
                )}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Grid>
    </Grid>
  );
}
export default ListaCandidature;
