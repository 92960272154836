import React from "react";
import { Grid2 as Grid, Card, CardContent, Divider, List, ListSubheader, IconButton, Tooltip, Avatar, useTheme } from "@mui/material";
import { ReactComponent as LOGO_CONFARTIGIANATO } from "../../immagini/JOB_LOGO_CONFER_BLUE.svg";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FactoryIcon from "@mui/icons-material/Factory";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation, useNavigate } from "react-router-dom";
import JobErItemMenu from "components/JobErItemMenu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useJobErController } from "context";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import AppBarAziende from "./AppBarAziende";

function LayoutAziende(props) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [controller] = useJobErController();
  const { authUser } = controller;

  return (
    <>
      <Grid
        container
        style={{
          minWidth: "100%",
          height: "fit-content",
        }}
        sx={{
          display: { xs: "none", md: "flex" },
          backgroundColor: theme.palette.primary.main + "!important",
        }}
      >
        <Grid
          size={2}
          sx={{
            padding: 2,
          }}
        >
          <Card sx={{ borderRadius: 7, height: "98vh" }}>
            <CardContent sx={{ paddingTop: 0 }}>
              <Grid container>
                <Grid
                  size={12}
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
                    <img
                      alt="logo_My_Lapam_Job"
                      src={require("../../immagini/MyLapamJOB_Logo.png")}
                      style={{
                        height: "70px",
                        width: "250px",
                        borderRadius: 15,
                      }}
                    />
                  ) : (
                    <LOGO_CONFARTIGIANATO className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_lg"></LOGO_CONFARTIGIANATO>
                  )}

                  <Divider />
                </Grid>
                <Grid size={12}>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <JobErItemMenu
                      title="Home"
                      active={location.pathname === "/Aziende/Home"}
                      icon={
                        <HomeIcon
                          sx={{
                            color: location.pathname === "/Aziende/Home" ? theme.palette.primary.main : theme.palette.grey.main,
                          }}
                        />
                      }
                      onClick={() => navigate("/Aziende/Home")}
                    />
                    <JobErItemMenu
                      title="Annunci"
                      active={location.pathname === "/Aziende/Annunci"}
                      icon={
                        <FactoryIcon
                          sx={{
                            color: location.pathname === "/Aziende/Annunci" ? theme.palette.primary.main : theme.palette.grey.main,
                          }}
                        />
                      }
                      onClick={() => navigate("/Aziende/Annunci")}
                    />
                    <JobErItemMenu
                      title="Candidature"
                      active={location.pathname === "/Aziende/Candidature"}
                      icon={
                        <HandshakeIcon
                          sx={{
                            color: location.pathname === "/Aziende/Candidature" ? theme.palette.primary.main : theme.palette.grey.main,
                          }}
                        />
                      }
                      onClick={() => navigate("/Aziende/Candidature")}
                    />
                    <JobErItemMenu
                      title="Presentazione"
                      active={location.pathname === "/Aziende/Profilo"}
                      icon={
                        <CoPresentIcon
                          sx={{
                            color: location.pathname === "/Aziende/Profilo" ? theme.palette.primary.main : theme.palette.grey.main,
                          }}
                        />
                      }
                      onClick={() => navigate("/Aziende/Profilo")}
                    />
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 10 }} style={{ padding: 12 }}>
          <Grid container justifyContent="flex-end" pr={2} mb={3} textAlign="right" spacing={1} alignSelf="center">
            <Grid size="auto" style={{ textAlign: "right!important", alignSelf: "center" }}>
              <Tooltip title="Logout">
                <IconButton
                  onClick={() => {
                    localStorage.removeItem("accessTokenJob");
                    localStorage.removeItem("partitaIvaJob");
                    navigate("/Vetrina/Home");
                  }}
                >
                  <PowerSettingsNewIcon sx={{ fontSize: 40, color: theme.palette.error.main }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid size="auto" style={{ textAlign: "right!important", alignSelf: "center" }}>
              <Grid container spacing={1}>
                <Grid size="auto">
                  {authUser?.miniatura ? (
                    <Tooltip title={authUser?.ragioneSociale ? authUser.ragioneSociale : null}>
                      <Avatar
                        sx={{ width: 40, height: 40 }}
                        onClick={() => {
                          navigate("/Aziende/Profilo");
                        }}
                        src={authUser.miniatura}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={authUser?.ragioneSociale ? authUser.ragioneSociale : null}>
                      <Avatar
                        sx={{ width: 40, height: 40 }}
                        onClick={() => {
                          navigate("/Aziende/Profilo");
                        }}
                      >
                        {authUser?.ragioneSociale ? authUser.ragioneSociale.substring(0, 2) : null}
                      </Avatar>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {props.child}
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          minWidth: "100%",
          height: "fit-content",
        }}
        sx={{
          display: { xs: "block", md: "none" },
          backgroundColor: theme.palette.primary.main + "!important",
        }}
      >
        <AppBarAziende />
        <Grid container>{props.child}</Grid>
      </Grid>
    </>
  );
}
export default LayoutAziende;
