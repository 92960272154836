import React, { useState, useEffect } from "react";
import { useJobErController } from "context";
import JobErCard from "components/JobErCard";
import { Grid2 as Grid, Typography, TextField, useTheme } from "@mui/material";
import { ReactComponent as LOGO_CONFARTIGIANATO } from "../../../immagini/JOB_LOGO_CONFER_BLUE.svg";
import JobErButton from "components/JobErButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { verificaPasswordDimenticata, reimpostaPassword } from "utils/utilsLogin";

function PasswordDimenticata(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [controller, dispatch] = useJobErController();
  const [searchParams] = useSearchParams();

  const [utente, setUtente] = useState({ password: "", ripetiPassword: "" });
  const [errori, setErrori] = useState({ password: "", ripetiPassword: "" });
  const [loading, setLoading] = useState(false);
  const [param, setParam] = useState({ tipo: null, email: null, token: null });

  useEffect(() => {
    searchParams.forEach((value, key) => {
      switch (key) {
        case "tipo":
          setParam((prevParam) => ({ ...prevParam, tipo: value }));
          break;
        case "email":
          setParam((prevParam) => ({ ...prevParam, email: value }));
          break;
        case "uuid":
          setParam((prevParam) => ({ ...prevParam, token: value }));
          break;
        default:
          navigate("/Home");
          break;
      }
    });
  }, []);

  const ReimpostaPassword = () => {
    if (utente?.password === utente?.ripetiPassword) {
      if (errori?.password === "ok" && errori?.ripetiPassword === "ok" && utente?.password && utente?.ripetiPassword) {
        reimpostaPassword(param, utente.password, navigate);
      } else {
        setErrori({ ...errori, password: "Compila correttamente i campi" });
      }
    } else {
      setErrori({ ...errori, password: "Le 2 password devono coincidere" });
    }
  };

  return (
    <Grid sx={{ backgroundColor: theme.palette.primary.main, height: "100vh" }} container direction="column" justifyContent="center" alignItems="center">
      <Grid size={{ xs: 12, md: 6 }}>
        <JobErCard
          child={
            <Grid container spacing={2}>
              <Grid size={12} textAlign="center">
                {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
                  <img
                    alt="Logo My Lapam Job"
                    src={require("../../../immagini/MyLapamJOB_Logo.png")}
                    style={{
                      height: "70px",
                      width: "280px",
                      borderRadius: 15,
                    }}
                  />
                ) : (
                  <LOGO_CONFARTIGIANATO className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_lg"></LOGO_CONFARTIGIANATO>
                )}
              </Grid>
              <Grid size={12} textAlign="center">
                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  size="small"
                  value={utente.password ? utente.password : ""}
                  onChange={(e) => setUtente({ ...utente, password: e.target.value })}
                  onBlur={(e) => verificaPasswordDimenticata(e.target.value, errori, setErrori, "password")}
                />
              </Grid>
              <Grid size={12} textAlign="center">
                <TextField
                  fullWidth
                  type="password"
                  label="Ripeti Password"
                  size="small"
                  value={utente.ripetiPassword ? utente.ripetiPassword : ""}
                  onChange={(e) => setUtente({ ...utente, ripetiPassword: e.target.value })}
                  onBlur={(e) => verificaPasswordDimenticata(e.target.value, errori, setErrori, "ripetiPassword")}
                />
              </Grid>
              <Grid size={12} textAlign="center">
                <Typography color="error">{errori.password !== "ok" && errori.password && errori.password}</Typography>
                <Typography color="error">{errori.ripetiPassword !== "ok" && errori.ripetiPassword && errori.ripetiPassword}</Typography>
              </Grid>
              <Grid size={12} textAlign="center">
                <JobErButton
                  onClick={() => {
                    ReimpostaPassword();
                  }}
                  isLoading={loading}
                >
                  Reimposta password
                </JobErButton>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
}
export default PasswordDimenticata;
