import React from "react";
import { Grid2 as Grid, TextField } from "@mui/material";

function AnnuncioStep6(props) {
  return (
    <Grid container mt={2}>
      <Grid size={12}>
        <Grid container justifyContent="center" pr={2} spacing={2} textAlign="left" mt={0}>
          <Grid size={12}>
            <TextField
              fullWidth
              label="Ulteriori competenze"
              size="small"
              value={props.dati ? props.dati.competenzeCandidato : ""}
              onChange={(e) => props.onSetDati("competenzeCandidato", e.target.value)}
              multiline
              rows={5}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              fullWidth
              label="Profilo ricercato"
              size="small"
              value={props.dati ? props.dati.profiloCandidato : ""}
              onChange={(e) => props.onSetDati("profiloCandidato", e.target.value)}
              multiline={true}
              rows={5}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep6;
