import React from "react";
import { Card, CardContent, Typography, Grid2 as Grid, useTheme } from "@mui/material";

function JobErCard(props) {
  const theme = useTheme();

  return (
    <Card style={{ borderRadius: 15, ...props.style }}>
      <CardContent>
        {props.title ? (
          <Grid container>
            <Grid size={12} textAlign="left" pb={1}>
              <Typography variant="h5" fontWeight="bold" color={theme.palette.primary.main}>
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        {props.child}
      </CardContent>
      {props.footer ? (
        <Grid container justifyContent="flex-end" p={2}>
          <Grid size={12} textAlign="right">
            {props.footer}
          </Grid>
        </Grid>
      ) : null}
    </Card>
  );
}
export default JobErCard;
