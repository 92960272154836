import React from "react";
import JobErCard from "components/JobErCard";
import FaceIcon from "@mui/icons-material/Face";
import { Grid2 as Grid } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import LanguageIcon from "@mui/icons-material/Language";
import WorkIcon from "@mui/icons-material/Work";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import JobErButton from "components/JobErButton";

function CardProfiloFiltriMobile({ setSelectedSection, selectedSection }) {
  return (
    <JobErCard
      title="Sezioni Profilo"
      child={
        <Grid container mt={1} spacing={2} justifyContent="center">
          <Grid size={12}>
            <JobErButton
              variant={selectedSection === "datiAnagrafici" ? "outlined" : "contained"}
              size="small"
              onClick={() => {
                setSelectedSection("datiAnagrafici");
              }}
            >
              <FaceIcon sx={{ mr: 1 }} />
              Dati Anagrafici
            </JobErButton>
          </Grid>
          <Grid size={12}>
            <JobErButton
              variant={selectedSection === "titoloStudio" ? "outlined" : "contained"}
              size="small"
              onClick={() => {
                setSelectedSection("titoloStudio");
              }}
            >
              <SchoolIcon sx={{ mr: 1 }} />
              Titoli Di Studio
            </JobErButton>
          </Grid>
          <Grid size={12}>
            <JobErButton
              variant={selectedSection === "lingue" ? "outlined" : "contained"}
              size="small"
              onClick={() => {
                setSelectedSection("lingue");
              }}
            >
              <LanguageIcon sx={{ mr: 1 }} />
              Lingue
            </JobErButton>
          </Grid>
          <Grid size={12}>
            <JobErButton
              variant={selectedSection === "esperienzeLavorative" ? "outlined" : "contained"}
              size="small"
              onClick={() => {
                setSelectedSection("esperienzeLavorative");
              }}
            >
              <WorkIcon sx={{ mr: 1 }} />
              Esperienze Lavorative
            </JobErButton>
          </Grid>
          <Grid size={12}>
            <JobErButton
              variant={selectedSection === "lavoriInteresse" ? "outlined" : "contained"}
              size="small"
              onClick={() => {
                setSelectedSection("lavoriInteresse");
              }}
            >
              <TipsAndUpdatesIcon sx={{ mr: 1 }} />
              Lavori Di Interesse
            </JobErButton>
          </Grid>
          <Grid size={12}>
            <JobErButton
              variant={selectedSection === "documenti" ? "outlined" : "contained"}
              size="small"
              onClick={() => {
                setSelectedSection("documenti");
              }}
            >
              <DocumentScannerIcon sx={{ mr: 1 }} />
              Documenti
            </JobErButton>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloFiltriMobile;
