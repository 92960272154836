import React, { useState } from "react";
import { Grid2 as Grid, Typography, Avatar, Collapse, useTheme } from "@mui/material";
import ChipsComune from "./chips/ChipsComune";
import ChipsSettore from "./chips/ChipsSettore";
import ChipsRal from "./chips/ChipsRal";
import ChipsLingua from "./chips/ChipsLingua";
import ChipsProgrammi from "./chips/ChipsProgrammi";
import ChipsStudi from "./chips/ChipsStudi";
import ChipsBenefit from "./chips/ChipsBenefit";
import JobErButton from "components/JobErButton";
import Swal from "sweetalert2";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function ViewAnnuncio(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Grid
      container
      spacing={2}
      onClick={handleClick}
      style={{ backgroundColor: props.annuncio?.uuid === props.searchParams ? theme.palette.searchSelected.main : theme.palette.white.main }}
      key={props.index}
      autoFocus={props.searchParams === props.annuncio?.uuid}
    >
      <Grid size={{ xs: 12, md: 3 }}>
        <Avatar
          src={
            props.annuncio?.codificaMansione?.gruppo
              ? require("../../immagini/ambiti_lavoro/gr" + props.annuncio.codificaMansione.gruppo + "-260x145.jpg")
              : null
          }
          sx={{
            width: "100%", // Fill the entire grid width
            height: 100, // Fill the entire grid height
            borderRadius: 5,
            objectFit: "cover",
          }}
          alt="immagine annuncio"
        />
      </Grid>
      <Grid size={{ xs: 12, md: 7 }}>
        <Typography component="p" fontWeight="bold">
          {props.annuncio?.titoloAnnuncio}
        </Typography>
        <Typography component="p">{props.annuncio?.comune?.des}</Typography>
        <Typography component="p">
          Data pubblicazione:
          {props.annuncio?.dataPubblicazione && props.annuncio ? " " + props.annuncio?.dataPubblicazione.split("-").reverse().join("/") : null}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 1 }} justifyContent="center" alignItems="center">
        {open ? <ExpandLess /> : <ExpandMore />}
      </Grid>
      <Grid size={{ xs: 12, md: 11 }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid container>
            <Grid size={12}>
              <Grid container spacing={1}>
                <Grid size={12} sx={{ textAlign: { xs: "center", md: "left" } }}>
                  <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.secondary">
                    {props.annuncio?.descrizioneLavoro ? (
                      <div style={{ fontSize: "1.9vh !Important" }} dangerouslySetInnerHTML={{ __html: props.annuncio.descrizioneLavoro }} />
                    ) : null}
                  </Typography>
                </Grid>
                <Grid size={12} sx={{ textAlign: { xs: "center", md: "left" } }}>
                  <Collapse in={true} timeout="auto" unmountOnExit>
                    <Grid container mt={1}>
                      <Grid size={12}>
                        <Grid container>
                          {props.annuncio?.comune ? (
                            <Grid size="auto" mr={1} mb={1}>
                              <ChipsComune label="comune" comune={props.annuncio?.comune?.des} />
                            </Grid>
                          ) : null}
                          {props.annuncio?.codificaMansione ? (
                            <Grid size="auto" mr={1} mb={1}>
                              <ChipsSettore settore={props.annuncio?.codificaMansione?.descrizione} />
                            </Grid>
                          ) : null}
                          {props.annuncio?.nettoMensile !== "-" || props.annuncio?.ral ? (
                            <Grid size="auto" mr={1} mb={1}>
                              <ChipsRal netto={props.annuncio?.nettoMensile} ral={props.annuncio?.ral} />
                            </Grid>
                          ) : null}
                          {props.annuncio?.linguaPrincipale ? (
                            <Grid size="auto" mr={1} mb={1}>
                              <ChipsLingua lingua={props.annuncio?.linguaPrincipale} />
                            </Grid>
                          ) : null}
                          {props.annuncio?.linguaSecondaria ? (
                            <Grid size="auto" mr={1} mb={1}>
                              <ChipsLingua
                                lingua={
                                  props.annuncio?.livelloLinguaSecondaria
                                    ? props.annuncio?.linguaSecondaria + " ( " + props.annuncio?.livelloLinguaSecondaria + " ) "
                                    : props.annuncio?.linguaSecondaria
                                }
                              />
                            </Grid>
                          ) : null}
                          {props.annuncio?.programmiRichiesti
                            ? props.annuncio?.programmiRichiesti.split(",").map((programma, index) => (
                                <Grid size="auto" mr={1} mb={1} key={"programmi_" + index}>
                                  <ChipsProgrammi programmi={programma} />
                                </Grid>
                              ))
                            : null}
                          {props.annuncio?.titoloStudio ? (
                            <Grid size="auto" mr={1} mb={1}>
                              <ChipsStudi studi={props.annuncio?.titoloStudio} />
                            </Grid>
                          ) : null}
                          {props.annuncio?.retribuzioneSupplementare
                            ? props.annuncio?.retribuzioneSupplementare.split(",").map((benefit, index) => (
                                <Grid size="auto" mr={1} mb={1} key={"benefict_" + index}>
                                  <ChipsBenefit benefit={benefit} />
                                </Grid>
                              ))
                            : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
                <Grid size={12} mt={1}>
                  <Grid container spacing={2} justifyContent="left">
                    <Grid size={{ xs: 12, md: "auto" }}>
                      <JobErButton
                        size="small"
                        color="success"
                        isLoading={props.pending}
                        onClick={() =>
                          Swal.fire({
                            text: "Sei sicuro di voler inviare questa candidatura?",
                            icon: "warning",
                            showCancelButton: true,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              props.onSendCandidatura(props.annuncio?.uuid);
                            } else if (result.isDenied) {
                            }
                          })
                        }
                      >
                        Invia Candidatura
                      </JobErButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
}
export default ViewAnnuncio;
