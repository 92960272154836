import React from "react";
import { Grid2 as Grid, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        height: "100vh",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
      }}
    >
      <Grid size={12} alignContent="center" textAlign="center">
        <Grid container textAlign="center">
          <Grid size={12} textAlign="center">
            <CircularProgress color="white" thickness={6} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Loading;
