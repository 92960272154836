import React from "react";
import { Grid2 as Grid } from "@mui/material";
import JobErButton from "components/JobErButton";
import StepperHomepage from "components/PortaleAziende/StepperHomepage";
import JobErCard from "components/JobErCard";
import { useNavigate } from "react-router-dom";

function HomeAziendePresentazione(props) {
  const navigate = useNavigate();

  return (
    <Grid size={{ xs: 12, md: 4 }}>
      <JobErCard
        title="Presentazione"
        child={
          <Grid container>
            <Grid size={12} mt={2}>
              <StepperHomepage presentazione={props.presentazione.dati} />
            </Grid>
            <Grid size={12} mt={2} textAlign="center">
              <JobErButton
                onClick={() => {
                  navigate("/Aziende/Profilo");
                }}
              >
                Gestisci presentazione
              </JobErButton>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
}

export default HomeAziendePresentazione;
