import JobErModal from "components/JobErModal";
import { Grid2 as Grid, Typography, useTheme } from "@mui/material";
import JobErButton from "components/JobErButton";
import PropTypes from "prop-types";
import { insertCandidaturaSpontanea } from "components/PortalePersone/utils/utils_candidatura";
import { useJobErController } from "context";

function CardAziendeHomepageDettaglio(props) {
  const theme = useTheme();
  const [controller] = useJobErController();
  const { authUser } = controller;

  return (
    <JobErModal
      fullWidth={true}
      maxWidth={"sm"}
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      modalBody={
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid size={{ xs: 12, md: 6 }} textAlign="center">
            <img
              alt="Copertina Azienda"
              src={props.azienda?.copertina}
              style={{
                maxHeight: "200px",
                width: "100%",
                borderRadius: 15,
              }}
            />
          </Grid>
          <Grid size={12}>
            <Typography variant="h6" color={theme.palette.primary.main} fontWeight="bold">
              {props.azienda?.ragioneSociale ? props.azienda.ragioneSociale : ""}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography>{props.azienda?.descrizione ? <div dangerouslySetInnerHTML={{ __html: props.azienda.descrizione }} /> : ""}</Typography>
          </Grid>
          {props.azienda?.sitoWeb && props.azienda?.sitoWeb !== "https://" && (
            <Grid size={12}>
              <a href={props.azienda?.sitoWeb && props.azienda?.sitoWeb !== "https://" ? props.azienda.sitoWeb : ""} target="_blank" rel="noreferrer">
                Sito web
              </a>
            </Grid>
          )}
        </Grid>
      }
      modalFooter={
        <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
          <Grid size={12}>
            <JobErButton
              variant="outlined"
              onClick={() => {
                insertCandidaturaSpontanea(props.azienda, authUser, props.setOpen);
              }}
            >
              {"Candidatura Spontanea"}
            </JobErButton>
            <JobErButton
              color="error"
              onClick={() => {
                props.setOpen(false);
              }}
            >
              {"Chiudi"}
            </JobErButton>
          </Grid>
        </Grid>
      }
    />
  );
}

CardAziendeHomepageDettaglio.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  azienda: PropTypes.object,
};

export default CardAziendeHomepageDettaglio;
