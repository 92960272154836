import pxToRem from "assets/theme-functions/pxToRem";

function boxShadow(offset = [], radius = [], color, opacity, inset = "") {
    const [x, y] = offset;
    const [blur, spread] = radius;

    return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${color}`;
}

export default boxShadow;
