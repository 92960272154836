import React, { useState, useContext } from "react";
import { Grid2 as Grid } from "@mui/material";
import LoginAzienda from "./LoginAzienda";
import LoginPersona from "./LoginPersona";
import SelezioneLogin from "./SelezioneLogin";
import { loginPersona, loginAzienda, passwordDimenticataAzienda, passwordDimenticataPersona } from "utils/utilsLogin";
import { useNavigate } from "react-router-dom";
import { useJobErController, setAuthUser } from "context";
import { JobErToast } from "components/JobErToast";
import JobErModal from "components/JobErModal";
import JobErButton from "components/JobErButton";
import { ModalContext } from "context/modalLogin";

function PopoverLogin(props) {
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);

  const [datiLogin, setDatiLogin] = useState({ username: null, password: null, piva: null, cf: null });
  const [errori, setErrori] = useState({ username: null, password: null, piva: null, cf: null });

  const [selectedTipoAccesso, setSelectedTipoAccesso] = useState(props.selectOption);
  const [loading, setLoading] = useState(false);

  const [statoAccesso, setStatoAccesso] = useState("credenziali");
  const [showButtonAccedi, setShowButtonAccedi] = useState(true);
  const navigate = useNavigate();
  const [controller, dispatch] = useJobErController();
  const [passwordDimenticata, setPasswordDimenticata] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const chiudiModal = () => {
    setSelectedTipoAccesso(null);
    setDatiLogin({ username: null, password: null, piva: null, cf: null });
    setErrori({ username: null, password: null, piva: null, cf: null });
    setPasswordDimenticata(false);
    setShowButtonAccedi(true);
    setLoading(false);
    setIsModalOpen(false);
  };

  const login = () => {
    if (datiLogin?.username && datiLogin?.password && errori?.username === "ok" && errori?.password === "ok") {
      setLoading(true);

      if (selectedTipoAccesso === "persona") {
        loginPersona(datiLogin.username, datiLogin.password)
          .then((user) => {
            chiudiModal();
            setAuthUser(dispatch, user);
            navigate("/Persone/Home");
          })
          .catch(function (e) {
            setLoading(false);
            JobErToast.fire({ icon: "error", title: "Utente non riconosciuto!" });
          });
      } else {
        loginAzienda(datiLogin.username, datiLogin.password)
          .then((user) => {
            chiudiModal();
            localStorage.setItem("partitaIvaJob", user.partitaIva);
            setAuthUser(dispatch, user);
            navigate("/Aziende/Home");
          })
          .catch(function (e) {
            setLoading(false);
            JobErToast.fire({ icon: "error", title: "Utente non riconosciuto!" });
          });
      }
    }
  };

  const reimpostaPassword = () => {
    if (selectedTipoAccesso === "persona") {
      if (datiLogin?.username && datiLogin?.cf && errori?.username === "ok" && errori?.cf === "ok") {
        passwordDimenticataPersona(datiLogin?.username, datiLogin?.cf.toUpperCase(), setLoading);
      }
    } else {
      if (datiLogin?.username && datiLogin?.piva && errori?.username === "ok" && errori?.piva === "ok") {
        passwordDimenticataAzienda(datiLogin?.username, datiLogin?.piva, setLoading);
      }
    }
  };

  return (
    <JobErModal
      fullWidth={false}
      maxWidth={"sm"}
      open={isModalOpen}
      onClose={handleClose}
      modalBody={
        <Grid container spacing={2}>
          <Grid size={12}>
            {(props.tipoAccesso && props.tipoAccesso) || selectedTipoAccesso === "azienda" ? (
              <LoginAzienda
                statoAccesso={statoAccesso}
                showButtonAccedi={showButtonAccedi}
                setShowButtonAccedi={setShowButtonAccedi}
                login={login}
                passwordDimenticata={passwordDimenticata}
                setPasswordDimenticata={setPasswordDimenticata}
                reimpostaPassword={reimpostaPassword}
                datiLogin={datiLogin}
                setDatiLogin={setDatiLogin}
                errori={errori}
                setErrori={setErrori}
              />
            ) : (props.tipoAccesso && props.tipoAccesso) || selectedTipoAccesso === "persona" ? (
              <LoginPersona
                showButtonAccedi={showButtonAccedi}
                setShowButtonAccedi={setShowButtonAccedi}
                login={login}
                passwordDimenticata={passwordDimenticata}
                setPasswordDimenticata={setPasswordDimenticata}
                reimpostaPassword={reimpostaPassword}
                datiLogin={datiLogin}
                setDatiLogin={setDatiLogin}
                errori={errori}
                setErrori={setErrori}
                /* onUserLogin={(user) => props.onUserLogin(user)} */
              />
            ) : (
              <SelezioneLogin onSelectTipoAccesso={(tipoAccesso) => setSelectedTipoAccesso(tipoAccesso)} />
            )}
          </Grid>
        </Grid>
      }
      modalFooter={
        <>
          {selectedTipoAccesso &&
            showButtonAccedi &&
            (passwordDimenticata ? (
              <JobErButton onClick={reimpostaPassword} isLoading={loading} color="primary" variant="outlined">
                Reimposta Password
              </JobErButton>
            ) : (
              <JobErButton size="large" onClick={login} isLoading={loading} color="primary">
                Accedi
              </JobErButton>
            ))}
          <JobErButton
            size="large"
            onClick={() => {
              chiudiModal();
            }}
            color="error"
          >
            Chiudi
          </JobErButton>
        </>
      }
    />
  );
}
export default PopoverLogin;
