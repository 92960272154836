import React from "react";
import Chip from "@mui/material/Chip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Grid2 as Grid, Avatar, useTheme } from "@mui/material";

function ChipsFiltriComuni(props) {
  const theme = useTheme();
  /* const handleDelete = () => {
    console.info("You clicked the delete icon.");
  }; */

  return props.comuni
    ? props.comuni.map((comune, index) => (
        <Grid key={index} size="auto">
          <Chip
            avatar={
              <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
                <LocationOnIcon sx={{ fontSize: "18px", color: theme.palette.white.main }} />
              </Avatar>
            }
            label={comune.label}
            /* onDelete={handleDelete} */
            sx={{
              "& .MuiChip-label": {
                fontSize: "14px",
              },
              "& .MuiChip-deleteIcon": {
                color: theme.palette.error.main,
                fontSize: "14px",
              },
            }}
            /* deleteIcon={<CloseIcon />} */
          />
        </Grid>
      ))
    : props.provincia
    ? props.provincia.map((provincia, index) => (
        <Grid key={index} size="auto">
          <Chip
            avatar={
              <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
                <LocationOnIcon sx={{ fontSize: "18px", color: theme.palette.white.main }} />
              </Avatar>
            }
            label={provincia.label}
            /* onDelete={handleDelete} */
            sx={{
              "& .MuiChip-label": {
                fontSize: "14px",
              },
              "& .MuiChip-deleteIcon": {
                color: theme.palette.error.main,
                fontSize: "14px",
              },
            }}
            /* deleteIcon={<CloseIcon />} */
          />
        </Grid>
      ))
    : null;
}
export default ChipsFiltriComuni;
