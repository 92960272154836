import React from "react";
import Chip from "@mui/material/Chip";
import LanguageIcon from "@mui/icons-material/Language";
import { Grid2 as Grid, Avatar, useTheme } from "@mui/material";

function ChipsLingua(props) {
  const theme = useTheme();
  return props.lingua ? (
    <Grid size="auto">
      <Chip
        avatar={
          <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
            <LanguageIcon sx={{ fontSize: "18px", color: theme.palette.white.main }} />
          </Avatar>
        }
        label={props.lingua}
        sx={{
          "& .MuiChip-label": {
            fontSize: "14px",
          },
          "& .MuiChip-deleteIcon": {
            color: theme.palette.error.main,
            fontSize: "14px",
          },
          textTransform: "capitalize",
        }}
      />
    </Grid>
  ) : null;
}
export default ChipsLingua;
