import React, { useState } from "react";
import { Grid2 as Grid, Typography, useTheme } from "@mui/material";
import { asyncRicercaComuni } from "utils/utilsRicerca";
import {
  completaRegistrazionePersona,
  verificaCampiRegistrazione,
  verificaEmailUtenteRegistrazione,
  verificaCodiceFiscaleRegistrazione,
  verificaPasswordRegistrazione,
} from "utils/utilsLogin";
import JobErButton from "components/JobErButton";
import options from "json/option_configuration.json";
import TextField from "@mui/material/TextField";
import JobErAutocomplete from "components/JobErAutocomplete";

function RegistrazionePersona(props) {
  const theme = useTheme();
  const [formData, setFormData] = useState({});
  const [dataError, setDataError] = useState([]);
  const [verificaMail, setVerificaMail] = useState(null);
  const [verificaCodiceFiscale, setVerificaCodiceFiscale] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verificaPassword, setVerificaPassword] = useState(null);
  const [comuni, setComuni] = useState([]);

  return (
    <>
      <Grid container justifyContent="center" spacing={1}>
        <Grid size={12} mt={3}>
          <Typography variant="h7" color={theme.palette.white.main} sx={{ textAlign: "center", fontWeight: "bold" }} helperText="Incorrect entry.">
            Dati utente
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            label="Email"
            size="small"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            onBlur={(e) => verificaEmailUtenteRegistrazione(e.target.value, setVerificaMail)}
            helperText={verificaMail === "forbidden" ? "Email già utilizzata" : verificaMail === "invalid" ? "Email non valida" : "Inserisci la tua email"}
            color="white"
            error={dataError.includes("email") && verificaMail !== "ok"}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            label="Password"
            size="small"
            type="password"
            color="white"
            value={formData.password || ""}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            onBlur={(e) => verificaPasswordRegistrazione(e.target.value, setVerificaPassword)}
            helperText={verificaPassword !== "ok" && verificaPassword ? verificaPassword : "Inserisci la password"}
            error={dataError.includes("password") && verificaPassword !== "ok"}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Ripeti Password"
            size="small"
            type="password"
            value={formData.ripetiPassword || ""}
            onChange={(e) => setFormData({ ...formData, ripetiPassword: e.target.value })}
            onBlur={(e) => verificaPasswordRegistrazione(e.target.value, setVerificaPassword)}
            helperText="Inserisci nuovamente la password"
            error={dataError.includes("ripetiPassword") && verificaPassword !== "ok"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={2}>
        <Grid size={12} mt={3}>
          <Typography variant="h7" color={theme.palette.white.main} sx={{ textAlign: "center", fontWeight: "bold" }}>
            Dati Anagrafici
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Nome"
            size="small"
            value={formData.nome || ""}
            onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
            helperText="Inserisci il tuo nome"
            error={dataError.includes("nome")}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Cognome"
            size="small"
            value={formData.cognome || ""}
            onChange={(e) => setFormData({ ...formData, cognome: e.target.value })}
            helperText="Inserisci il tuo cognome"
            error={dataError.includes("cognome")}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Codice Fiscale"
            size="small"
            value={formData.codiceFiscale || ""}
            onChange={(e) => setFormData({ ...formData, codiceFiscale: e.target.value })}
            helperText={verificaCodiceFiscale === "invalid" ? "Codice fiscale non valido" : "Inserisci il tuo codice fiscale"}
            onBlur={(e) => verificaCodiceFiscaleRegistrazione(e.target.value, setVerificaCodiceFiscale)}
            error={dataError.includes("codiceFiscale") && verificaCodiceFiscale !== "ok"}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <JobErAutocomplete
            size="small"
            color="white"
            label="Ricerca Comune"
            value={formData.comune}
            error={dataError.includes("comune")}
            onInput={(e) => {
              let inputValue = e.target.value;
              if (inputValue?.length >= 3) {
                asyncRicercaComuni(inputValue).then((response) => {
                  if (response) {
                    setComuni(response);
                  }
                });
              }
            }}
            options={comuni}
            onChange={(data, newValue) => setFormData({ ...formData, comune: newValue })}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Indirizzo"
            size="small"
            value={formData.indirizzo || ""}
            onChange={(e) => setFormData({ ...formData, indirizzo: e.target.value })}
            helperText="Inserisci il tuo indirizzo"
            error={dataError.includes("indirizzo")}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Civico"
            size="small"
            value={formData.civico || ""}
            onChange={(e) => setFormData({ ...formData, civico: e.target.value })}
            helperText="Inserisci il tuo N. civico"
            error={dataError.includes("civico")}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="CAP"
            size="small"
            value={formData.cap || ""}
            onChange={(e) => setFormData({ ...formData, cap: e.target.value })}
            helperText="Inserisci il tuo cap"
            error={dataError.includes("cap")}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <JobErAutocomplete
            color="white"
            size="small"
            label="Fai parte di una categoria protetta?"
            options={options.yes_no}
            error={dataError.includes("categoriaSpeciale")}
            value={formData?.categoriaSpeciale ? formData.categoriaSpeciale : null}
            onChange={(data, newValue) => setFormData({ ...formData, categoriaSpeciale: newValue })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={2}>
        <Grid size={12}>
          <JobErButton
            color="white"
            isLoading={loading}
            onClick={() => {
              if (verificaCampiRegistrazione("persona", formData, setDataError)) {
                if (verificaMail === "ok" && verificaCodiceFiscale === "ok") {
                  completaRegistrazionePersona(formData, props.onRegistrazioneCompletata, setLoading);
                }
              }
            }}
          >
            Completa Registrazione
          </JobErButton>
        </Grid>
      </Grid>
    </>
  );
}
export default RegistrazionePersona;
