const colorsPalette1 = {
  mode: "light",

  background: {
    default: "#002B48",
    paper: "#fefefe",
    contrast: "#101010",
  },
  text: {
    primary: "#343434",
  },
  divider: "#dddddd",

  neutral: {
    main: "#ffffff",
    dark: "#dddddd",
    light: "#ffffff",
    contrastText: "#45526c",
  },

  primary: {
    main: "#002B48",
  },

  secondary: {
    main: "#ADB0FF",
  },

  info: {
    main: "#fefefe",
  },

  success: {
    main: "#44A180",
  },

  warning: {
    main: "#ffb74d",
  },

  error: {
    main: "#f5365cd4",
  },

  action: {
    disabled: "#00000022",
  },

  white: {
    main: "#fefefe",
  },

  black: {
    main: "#121212",
  },

  grey: {
    main: "#74748e",
  },

  searchSelected: {
    main: "#E6E6E6",
  },

  menu: {
    itemIconColor: "#44a180",
    itemTextColor: "#45526c",
    logoColor: "#44a180",
    backgroundStyle: `#fefefe`,
    backgroundColor: `#fefefe`,
    borderStyle: "2px solid #b6ddd0",
    activeMenuColor: "#44a180",
    toggleButtonColor: "#44a180",
  },

  login: {
    gradientLogin: `linear-gradient(66deg, #44a180 10%, #64b3f4 250%);`,
  },
};

export default colorsPalette1;
