import React, { useState } from "react";
import { Grid2 as Grid, Typography, TextField } from "@mui/material";
import { JobErToast } from "components/JobErToast";
import JobErButton from "components/JobErButton";

function RichiestaInformazioni(props) {
  const [informazioni, setInformazioni] = useState({
    uuid: null,
    richiedente: "persona",
    ragioneSociale: null,
    partitaIva: null,
    email: null,
    richiesta: null,
    nome: null,
    cognome: null,
  });
  const [dataError, setDataError] = useState([]);
  const checkField = ["nome", "cognome", "email", "richiesta"];
  const [verificaEmail, setVerificaEmail] = useState(null);
  const [pending, setPending] = useState(false);

  const inviaRichiesta = () => {
    var objError = [];
    var checkMail = null;

    for (var i = 0; i < checkField.length; i++) {
      if (!informazioni[checkField[i]]) {
        objError.push(checkField[i]);
      }
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(informazioni.email) && informazioni.email) {
      checkMail = "ok";
    } else {
      checkMail = "invalid";
    }

    setDataError(objError);
    setVerificaEmail(checkMail);

    if (objError.length === 0 && checkMail === "ok") {
      setPending(true);

      const params = new URLSearchParams({
        linkAmbiente: process.env.REACT_APP_URL_FE,
        ambiente: process.env.REACT_APP_AMBIENTE,
      });

      fetch(process.env.REACT_APP_URL + "/vetrina/richiestaContatto?" + params, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(informazioni),
      }).then((res) => {
        if (res.status === 200) {
          setInformazioni({
            uuid: null,
            richiedente: "persona",
            ragioneSociale: null,
            partitaIva: null,
            email: null,
            richiesta: null,
            nome: null,
            cognome: null,
          });
          JobErToast.fire({ icon: "success", title: "Richiesta inviata!" });
        } else {
          JobErToast.fire({ icon: "error", title: "La tua richiesta non è stata inoltrata, riprova!" });
        }

        setPending(false);
      });
    }
  };

  return (
    <Grid size={12}>
      <Grid container sx={{ justifyContent: "center" }} spacing={3}>
        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Nome"
            size="small"
            helperText="Inserisci il tuo nome"
            value={informazioni.nome ? informazioni.nome : null}
            error={dataError.includes("nome")}
            onChange={(e) => setInformazioni({ ...informazioni, nome: e.target.value })}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Cognome"
            size="small"
            helperText=" Inserisci il tuo cognome"
            error={dataError.includes("cognome")}
            value={informazioni.cognome ? informazioni.cognome : null}
            onChange={(e) => setInformazioni({ ...informazioni, cognome: e.target.value })}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
          <TextField
            fullWidth
            color="white"
            label="Email"
            size="small"
            error={dataError.includes("email")}
            helperText={verificaEmail === "forbidden" ? "Email già utilizzata" : verificaEmail === "invalid" ? "Email non valida" : "Inserisci la tua email"}
            value={informazioni.email ? informazioni.email : null}
            onChange={(e) => setInformazioni({ ...informazioni, email: e.target.value })}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            fullWidth
            color="white"
            label="Richiesta"
            multiline={true}
            rows={5}
            size="small"
            error={dataError.includes("richiesta")}
            helperText="Inserisci la tua richiesta"
            value={informazioni.richiesta ? informazioni.richiesta : null}
            onChange={(e) => setInformazioni({ ...informazioni, richiesta: e.target.value })}
          />
        </Grid>
        <Grid
          size={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <JobErButton color="white" variant="contained" size="large" onClick={inviaRichiesta} isLoading={pending}>
            Invia Richiesta
          </JobErButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default RichiestaInformazioni;
