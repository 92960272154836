import React from "react";
import "../css/JobEr.css";
import { Button, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const JobErButton = forwardRef(function renderBtnFx(
  { color, variant = "contained", size = "small", onClick, title = "", circular = false, iconOnly = false, children, isLoading = false, ...rest },
  ref
) {
  return (
    <Tooltip title={title}>
      <Button ref={ref} color={color} variant={variant} size={size} onClick={!isLoading ? onClick : undefined} {...rest} className="btn-bb-slim">
        {isLoading ? (
          <CircularProgress
            size={25}
            sx={{
              zIndex: 1,
            }}
          />
        ) : (
          children
        )}
      </Button>
    </Tooltip>
  );
});

JobErButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf(["white", "primary", "secondary", "info", "success", "warning", "error", "light"]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.node,
};

export default JobErButton;
