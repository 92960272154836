import React from "react";
import { Grid2 as Grid } from "@mui/material";
import options from "../../json/option_configuration.json";
import { convertOrariLavoroToOptSelect } from "pages/portale_aziende/utils/utilsAnnunci";
import JobErAutocomplete from "components/JobErAutocomplete";

function AnnuncioStep3(props) {
  return (
    <Grid container spacing={3} mt={6}>
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          size="small"
          label="Trasferte temporanee"
          value={
            props.dati?.trasferteTemporanee
              ? options.yes_no.find((opt) => opt.label === props.dati.trasferteTemporanee || opt.value === props.dati.trasferteTemporanee)
              : null
          }
          options={options.yes_no}
          onChange={(event, newValue) => {
            props.onSetDati("trasferteTemporanee", newValue ? newValue.value : null);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          size="small"
          label="Trasferte definitive"
          value={
            props.dati && props.dati.trasferteDefinitive
              ? options.yes_no.find((opt) => opt.label === props.dati.trasferteDefinitive || opt.value === props.dati.trasferteDefinitive)
              : null
          }
          options={options.yes_no}
          onChange={(event, newValue) => {
            props.onSetDati("trasferteDefinitive", newValue ? newValue.value : null);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          size="small"
          label="Automunito"
          value={props.dati?.automunito ? options.yes_no.find((opt) => opt.label === props.dati.automunito || opt.value === props.dati.automunito) : null}
          options={options.yes_no}
          onChange={(event, newValue) => {
            props.onSetDati("automunito", newValue ? newValue.value : null);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <JobErAutocomplete
          multiple
          size="small"
          label="Orari lavoro"
          value={
            props.dati?.orariLavoro && typeof props.dati.orariLavoro === "object"
              ? props.dati.orariLavoro
              : props.dati?.orariLavoro && typeof props.dati.orariLavoro === "string"
              ? convertOrariLavoroToOptSelect(props.dati.orariLavoro)
              : []
          }
          options={options.turni_lavoro}
          onChange={(event, newValue) => {
            props.onSetDati("orariLavoro", newValue);
          }}
        />
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep3;
