import { getAuthHeader } from "assets/utils/auth-services";
import { JobErToast } from "components/JobErToast";

export function getAziendeHomepage(provinciaSelezionata, setAziendeTerritorio, setLoading, page, setPagination, filtroAzienda) {
  setLoading(true);

  const params = new URLSearchParams({
    filtro: filtroAzienda,
  });

  fetch(
    process.env.REACT_APP_URL + "/persone/getListaAziendeProvincia/" + (provinciaSelezionata ? provinciaSelezionata.value : "null") + "/" + page + "?" + params,
    {
      headers: getAuthHeader(),
    }
  )
    .then((res) => {
      if (!res.ok) {
        JobErToast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
        return null;
      }
      return res.json();
    })
    .then(
      (result) => {
        setAziendeTerritorio(result.content);
        setPagination(result);
        setLoading(false);
      },
      (error) => {
        console.error("Si è verificato un errore");
        JobErToast.fire({ icon: "error", title: "Si è verificato un errore" });
        setLoading(false);
      }
    );
}
