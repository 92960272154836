import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Grid2 as Grid, Avatar, useTheme, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useJobErController } from "context";
import JobErAutocomplete from "components/JobErAutocomplete";
import JobErButton from "components/JobErButton";

function MenuAvatarPortableDevice(props) {
  const theme = useTheme();
  const [controller] = useJobErController();
  const { authUser } = controller;
  const [optionAziende, setOptionAziende] = useState([]);
  const [aziendaSelezionata, setAziendaSelezionata] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    if (authUser && authUser.abbinamenti && authUser.abbinamenti.length > 1) {
      var opzioni = [];
      authUser.abbinamenti.map((abbinamento) =>
        opzioni.push({
          label: abbinamento.anagraficaAzienda.ragioneSociale,
          value: abbinamento.anagraficaAzienda.partitaIva,
          disabled: abbinamento.anagraficaAzienda.partitaIva === localStorage.getItem("partitaIvaJob") ? true : false,
        })
      );
      setOptionAziende(opzioni);
      var gestita = authUser.abbinamenti.filter((abbinamento) => abbinamento.partitaIva === localStorage.getItem("partitaIvaJob"));
      if (gestita && gestita[0]) {
        setAziendaSelezionata({
          label: gestita[0].anagraficaAzienda.ragioneSociale,
          value: gestita[0].anagraficaAzienda.partitaIva,
        });
      }
    }
  }, [authUser]);

  return (
    <>
      <Avatar alt="profilo" sx={{ width: 40, height: 40 }} onClick={handleOpenNavMenu}>
        {authUser?.ragioneSociale ? authUser.ragioneSociale.substring(0, 2) : null}
      </Avatar>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiPaper-root": {
            borderTopRightRadius: "20px!important",
            borderBottomRightRadius: "20px!important",
            borderBottomLeftRadius: "20px!important",
            width: "90%",
          },
        }}
      >
        <MenuItem key={"headerlogin_menu_xs"}>
          <Grid container justifyContent="center">
            <Grid size="auto"></Grid>
            <Grid size={12} textAlign="center">
              <Typography
                color={theme.palette.primary.main}
                fontWeight="bold"
                sx={{
                  textTransform: "uppercase",
                  whiteSpace: "normal",
                }}
              >
                Stai gestendo
              </Typography>
            </Grid>
            <Grid size={12} textAlign="center">
              <JobErAutocomplete
                size="small"
                label="Azienda gestita"
                value={aziendaSelezionata}
                options={optionAziende}
                onChange={(event, newValue) => {
                  setAziendaSelezionata(newValue);
                }}
              />
            </Grid>
            <Grid size={12} mt={1} textAlign="center">
              <JobErButton onClick={() => null}>Gestisci</JobErButton>
            </Grid>
            <Grid size={12} mt={1} textAlign="center">
              <Divider />
            </Grid>
            <Grid size={12} mt={2} textAlign="center">
              <Typography variant="h4" color={theme.palette.primary.main} fontWeight="bold">
                {authUser?.ragioneSociale && authUser?.ragioneSociale}
              </Typography>
            </Grid>
            <Grid size={12} mb={2} textAlign="center">
              <Typography variant="h4" color={theme.palette.primary.main}>
                {authUser?.email ? authUser.email : ""}
              </Typography>
            </Grid>
            <Grid size={12} textAlign="center" mt={1}>
              <JobErButton
                color="error"
                onClick={() => {
                  localStorage.removeItem("accessTokenJob");
                  localStorage.removeItem("partitaIvaJob");
                  navigate("/Vetrina/Home");
                }}
              >
                Logout
              </JobErButton>
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  );
}
export default MenuAvatarPortableDevice;
