import React from "react";
import { Grid2 as Grid, Typography, ListItemAvatar, ListItemText, Avatar, ListItemButton, Chip, useTheme } from "@mui/material";
import { dataNascitaDaCodiceFiscale } from "utils/utilsDati";
import JobErButton from "components/JobErButton";
import { downloadDocumento } from "utils/utilsDati";
import { setStatisticheDownloadCv } from "components/PortaleAziende/utils/utils_candidature";
import { useJobErController } from "context";

function ViewCandidatura(props) {
  const theme = useTheme();
  const [controller, dispatch] = useJobErController();
  const { authUser } = controller;

  const apriDettaglio = (uuid) => {
    if (uuid) {
      props.setOpenDettaglio(uuid);
    }
  };

  return (
    <ListItemButton
      sx={{ backgroundColor: props.candidatura.uuidCandidatura === props.uuidParam ? theme.palette.searchSelected.main : theme.palette.white.main }}
      alignItems="flex-start"
      key={props.candidatura.uuidCandidatura}
      autoFocus={props.candidatura.uuidCandidatura === props.uuidParam}
    >
      <ListItemAvatar>
        {props.candidatura?.immagineProfilo ? (
          <Avatar src={props.candidatura.immagineProfilo}></Avatar>
        ) : (
          <Avatar
            sx={{
              fontWeight: "bold",
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.white.main,
            }}
          >
            {props.candidatura?.nominativo ? props.candidatura.nominativo.substring(0, 1) : null}
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        key={"_text"}
        sx={{ marginLeft: 2 }}
        primary={
          props.candidatura.nominativo
            ? props.candidatura.nominativo.toUpperCase() +
              " - " +
              (props.candidatura.titoloAnnuncio ? props.candidatura.titoloAnnuncio.toLowerCase() : "candidatura spontanea")
            : null
        }
        primaryTypographyProps={{
          fontWeight: "bold",
          color: theme.palette.primary.main,
        }}
        secondary={
          <Grid container>
            <Grid size="auto">
              <Typography color={theme.palette.primary.main} variant="subtitle1">
                Candidature: <strong>{props.candidati.filter((obj) => obj.codiceFiscale === props.candidatura.codiceFiscale).length}</strong>
              </Typography>
            </Grid>
            <Grid size="auto" ml={2}>
              <JobErButton
                size="small"
                onClick={() => {
                  apriDettaglio(props.candidatura.uuidCandidatura);
                }}
              >
                Dettaglio
              </JobErButton>
            </Grid>

            {props.candidatura?.documento && (
              <Grid size="auto" ml={2}>
                <JobErButton
                  size="small"
                  onClick={() => {
                    let nomeFile = props.candidatura.nominativo?.toUpperCase() + " - " + props.candidatura.titoloAnnuncio?.toLowerCase();

                    downloadDocumento(props.candidatura.documento, nomeFile);
                    setStatisticheDownloadCv(authUser);
                  }}
                >
                  Cv
                </JobErButton>
              </Grid>
            )}
            <Grid size="auto" ml={2}>
              <Chip label={props.candidatura.statoLabel} />
            </Grid>
            <Grid
              size={12}
              p={1}
              id={props.candidatura.uuidCandidatura}
              sx={{ display: props.openDettaglio === props.candidatura.uuidCandidatura ? "inline" : "none" }}
            >
              <Grid container>
                <Grid size={{ xs: 12, md: 6 }} p={0.5}>
                  <Typography color={theme.palette.primary.main} variant="subtitle1">
                    Codice Fiscale: <strong>{props.candidatura?.codiceFiscaleCandidato}</strong>
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} p={0.5}>
                  <Typography color={theme.palette.primary.main} variant="subtitle1">
                    Data di Nascita: <strong>{dataNascitaDaCodiceFiscale(props.candidatura?.codiceFiscaleCandidato)}</strong>
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} p={0.5}>
                  <Typography color={theme.palette.primary.main} variant="subtitle1">
                    Telefono: <strong>{props.candidatura?.telefono}</strong>
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} p={0.5}>
                  <Typography color={theme.palette.primary.main} variant="subtitle1">
                    Mail: <strong>{props.candidatura?.user}</strong>
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} p={0.5}>
                  <Typography color={theme.palette.primary.main} variant="subtitle1">
                    Comune Residenza: <strong>{props.candidatura?.comuneResidenza}</strong>
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} p={0.5}>
                  <Typography color={theme.palette.primary.main} variant="subtitle1">
                    Indirizzo Residenza: <strong>{props.candidatura?.indirizzo}</strong>
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} p={0.5}>
                  <Typography color={theme.palette.primary.main} variant="subtitle1">
                    Stato Civile: <strong>{props.candidatura?.statoCivile}</strong>
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} p={0.5}>
                  <Typography color={theme.palette.primary.main} variant="subtitle1">
                    Patente: <strong>{props.candidatura?.patenti}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </ListItemButton>
  );
}

export default ViewCandidatura;
