import React, { useEffect, useState } from "react";
import { Grid2 as Grid, TextField } from "@mui/material";
import { asyncRicercaRuoli, asyncRicercaComuni } from "utils/utilsRicerca";
import { convertCodificaMansioneToOptMansione } from "pages/portale_aziende/utils/utilsAnnunci";
import { getSediAzienda } from "pages/portale_aziende/utils/utilsHomepage";
import JobErAutocomplete from "components/JobErAutocomplete";

function AnnuncioStep1(props) {
  const [optionSedi, setOptionSedi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [optionRuoli, setOptionRuoli] = useState([]);
  const [optionComuni, setOptionComuni] = useState([]);

  useEffect(() => {
    getSediAzienda(localStorage.getItem("partitaIvaJob")).then((res) => {
      var arraySede = [];
      res.map((sede) =>
        arraySede.push({
          label: sede.descrizioneComune.toUpperCase() + " - " + sede.indirizzo.toUpperCase(),
          value: sede.indirizzo + "_" + sede.comune,
          comune: sede.comune,
        })
      );
      setOptionSedi(arraySede);
    });
  }, []);

  return (
    <Grid container spacing={3} mt={6}>
      <Grid size={{ xs: 12, md: 6 }}>
        <TextField
          fullWidth
          label="Titolo annuncio *"
          size="small"
          value={props.dati ? props.dati.titoloAnnuncio : ""}
          onChange={(e) => props.onSetDati("titoloAnnuncio", e.target.value)}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <TextField fullWidth disabled label="Data pubblicazione" size="small" value={props.dati ? props.dati.dataPubblicazione : ""} />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <JobErAutocomplete
          onClose={() => {
            setOptionRuoli([]);
          }}
          loading={loading}
          size="small"
          label="Ruolo *"
          value={
            props.dati && props.dati.posizioneLavorativa
              ? props.dati.posizioneLavorativa
              : props.dati && props.dati.codificaMansione
              ? convertCodificaMansioneToOptMansione(props.dati.codificaMansione)
              : null
          }
          onInput={(e) => {
            let inputValue = e.target.value;

            if (inputValue?.length >= 3) {
              setLoading(true);
              asyncRicercaRuoli(inputValue).then((response) => {
                if (response) {
                  setOptionRuoli(response);
                  setLoading(false);
                }
              });
            }
          }}
          options={optionRuoli}
          onChange={(data, newValue) => {
            if (newValue) {
              props.onSetDati("posizioneLavorativa", newValue);
            }
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }} textAlign="left">
        <JobErAutocomplete
          size="small"
          label="Sede lavoro *"
          value={
            props.dati?.indirizzo && typeof props.dati.indirizzo === "object"
              ? props.dati.indirizzo
              : props.dati && props.dati.indirizzo && typeof props.dati.indirizzo === "string"
              ? {
                  label: props.dati.indirizzo,
                  value: props.dati.indirizzo,
                }
              : null
          }
          options={optionSedi}
          onChange={(event, newValue) => {
            props.onSetDati("indirizzo", newValue);
          }}
          onInputChange={(e) => {
            let opts = [...optionSedi].filter((opt) => opt.value !== "custom");
            if (e) {
              opts.push({
                value: "custom",
                label: e,
              });
            }

            setOptionSedi(opts);
          }}
        />
      </Grid>
      {props.dati && props.dati.indirizzo && props.dati.indirizzo.value === "custom" ? (
        <Grid size={{ xs: 12, md: 3 }} textAlign="left">
          <JobErAutocomplete
            onClose={() => {
              setOptionComuni([]);
            }}
            loading={loading}
            size="small"
            label="Comune"
            value={props.dati?.comuneSede ? props.dati.comuneSede : null}
            onInput={(e) => {
              let inputValue = e.target.value;

              if (inputValue?.length >= 3) {
                setLoading(true);
                asyncRicercaComuni(inputValue).then((response) => {
                  if (response) {
                    setOptionComuni(response);
                    setLoading(false);
                  }
                });
              }
            }}
            options={optionComuni}
            onChange={(data, newValue) => {
              props.onSetDati("comuneSede", newValue);
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
export default AnnuncioStep1;
