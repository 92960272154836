import Swal from "sweetalert2";
import "../css/styleJobErToast.css";

export const JobErToast = Swal.mixin({
  JobErToast: true,
  position: "top-end",
  iconColor: "white",
  customClass: {
    popup: "colored-JobErToast",
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});
