import { useTheme } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_IT } from "material-react-table/locales/it";

function JobErTable(props) {
  const theme = useTheme();
  const table = useMaterialReactTable({
    localization: MRT_Localization_IT,
    columns: props.columns,
    data: props.data,
    state: { isLoading: props.pending },
    initialState: {
      pagination: { pageSize: 6, pageIndex: 0 },
    },
    muiTablePaperProps: ({ table }) => ({
      elevation: 3,
      sx: {
        borderRadius: 4,
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.primary.main,
      },
    },
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.primary.main,
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.primary.main,
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.primary.main,
      },
    },
    muiTableProps: {
      sx: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.primary.main,
      },
    },
    muiTableBodyProps: {
      sx: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.primary.main,
      },
    },
  });

  return <MaterialReactTable table={table} />;
}

export default JobErTable;
