import colorsPalette1 from "assets/palette/colorsPalette1";
import breakpoints from "assets/base/breakpoints";
import typography from "assets/base/typography";
import boxShadows from "assets/base/boxShadows";
import borders from "assets/base/borders";
import boxShadow from "assets/theme-functions/boxShadow";
import linearGradient from "assets/theme-functions/linearGradient";
import pxToRem from "assets/theme-functions/pxToRem";
import fontSizes from "assets/base/fontSizes";
import textfield from "assets/theme-component/form/textfield";
import outlinedInput from "assets/theme-component/form/outlinedInput";
import autocomplete from "assets/theme-component/form/autocomplete";
import inputLabel from "assets/theme-component/form/inputLabel";

export function themeFunction() {
  return {
    breakpoints: { ...breakpoints },
    palette: { ...colorsPalette1 },
    typography: { ...typography },
    boxShadows: { ...boxShadows },
    borders: { ...borders },
    fontSizes: { ...fontSizes },
    functions: {
      boxShadow,
      linearGradient,
      pxToRem,
    },
    components: {
      MuiAutocomplete: { ...autocomplete },
      MuiInputLabel: { ...inputLabel },
      MuiTextField: { ...textfield },
      MuiOutlinedInput: { ...outlinedInput },
    },
    root: {},
  };
}
