import React, { useContext } from "react";
import { Grid2 as Grid, Typography, useTheme } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useNavigate } from "react-router-dom";
import JobErButton from "components/JobErButton";
import PropTypes from "prop-types";
import { ModalContext } from "context/modalLogin";

function RegistrazioneCompletata(props) {
  const theme = useTheme();
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    props.data !== "error" &&
    (props.tipoUtente === "persona" || props.tipoUtente === "azienda") && (
      <Grid container spacing={3} pl={20} pr={20} mt={1} justifyContent="center">
        <Grid size={12}>
          <TaskAltIcon sx={{ fontSize: 150, color: theme.palette.success.main }} />
        </Grid>
        <Grid size={12} mt={2}>
          <Typography
            variant="h4"
            color={theme.palette.white.main}
            sx={{
              textAlign: "center!important",
              fontWeight: "bold",
            }}
          >
            Registrazione Completata!
          </Typography>
        </Grid>
        <Grid size={12}>
          <Typography variant="h5" color={theme.palette.white.main} sx={{ textAlign: "center!important" }} mt={2}>
            Grazie <b>{props.tipoUtente === "azienda" ? props.data?.ragioneSociale : props.data?.nome}</b> per esserti iscritto al nostro portale.
          </Typography>
        </Grid>
        <Grid size={12}>
          <Typography variant="h5" color={theme.palette.white.main} sx={{ textAlign: "center!important" }} mt={2}>
            Clicca sul link che ti abbiamo inoltrato per email per confermare il tuo indirizzo di posta elettronica e accedere subito a tutti i servizi dedicati
            a te
          </Typography>
        </Grid>
        <Grid size="auto">
          <JobErButton
            size="large"
            onClick={() => {
              navigate("/Vetrina/Home");
            }}
          >
            Torna Alla Home
          </JobErButton>
        </Grid>
        <Grid size="auto">
          <JobErButton
            size="large"
            color="white"
            variant="contained"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Accedi
          </JobErButton>
        </Grid>
      </Grid>
    )
  );
}

RegistrazioneCompletata.propTypes = {
  data: PropTypes.object,
  tipoUtente: PropTypes.string,
};

export default RegistrazioneCompletata;
