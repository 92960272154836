import options from "../../../json/option_configuration.json";
import { VerificaStep } from "pages/portale_aziende/utils/VerificaStep";
import { getAuthHeader } from "assets/utils/auth-services";
import moment from "moment";
import { JobErToast } from "components/JobErToast";

export function disponibilitaTipologiaAnnunci(authUser, setOptions) {
  fetch(process.env.REACT_APP_URL + "/aziende/disponibilitaTipologiaAnnunci/" + authUser.codConfartigianato, {
    headers: getAuthHeader(),
    method: "POST",
  })
    .then((res) => {
      if (!res.ok) {
        JobErToast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
        return null;
      }
      return res.json();
    })
    .then((result) => {
      if (result.length > 0) {
        setOptions(result);
      }
    })
    .catch((err) => {
      console.error("Si è verificato un errore nel caricamento degli annunci");
      JobErToast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento degli annunci" });
    });
}

async function mapObjToObjAnnuncio(dati) {
  return new Promise((resolve, reject) => {
    var annuncio = { ...dati };

    if (dati.uuid) {
      annuncio.uuid = dati.uuid;
    }

    if (dati.benefit) {
      var benefit = dati.benefit.map((obj) => obj.value);
      annuncio.benefit = benefit.toString();
    }
    if (dati.orariLavoro) {
      var orariLavoro = dati.orariLavoro.map((obj) => obj.value);
      annuncio.orariLavoro = orariLavoro.toString();
    }
    if (dati.retribuzioneSupplementare) {
      var retribuzioneSupplementare = dati.retribuzioneSupplementare.map((obj) => obj.value);
      annuncio.retribuzioneSupplementare = retribuzioneSupplementare.toString();
    }
    if (dati.richiestaEsperienza) {
      annuncio.richiestaEsperienza = dati.richiestaEsperienza.value;
    }

    if (dati.posizioneLavorativa) {
      annuncio.posizioneLavorativa = dati.posizioneLavorativa.uuid;
    } else {
      if (dati.codificaMansione && dati.codificaMansione.uuid) {
        annuncio.posizioneLavorativa = dati.codificaMansione.uuid;
      }
    }

    if (dati.indirizzo) {
      annuncio.indirizzo = dati.indirizzo.label;
      annuncio.comuneLavoro = dati.indirizzo ? dati.indirizzo.comune : dati.comune.codiceComune;
    }

    if (dati.comuneSede) {
      annuncio.comuneLavoro = dati.comuneSede.value;
    }

    if (dati.tipoRicerca && typeof dati.tipoRicerca === "object") {
      annuncio.tipoRicerca = dati.tipoRicerca.value;
    }

    if (dati.dataPubblicazione) {
      annuncio.dataPubblicazione = dati.dataPubblicazione.split("/").reverse().join("-");
    }
    if (dati.richiestoTitolo) {
      annuncio.richiestoTitolo = dati.richiestoTitolo.value;
    }
    if (dati.linguaPrincipale) {
      annuncio.linguaPrincipale = dati.linguaPrincipale.value;
    }
    if (dati.linguaSecondaria) {
      annuncio.linguaSecondaria = dati.linguaSecondaria.value;
    }
    if (dati.linguaSecondaria) {
      annuncio.linguaSecondaria = dati.linguaSecondaria.value;
    }
    if (dati.livelloLinguaSecondaria) {
      annuncio.livelloLinguaSecondaria = dati.livelloLinguaSecondaria.value;
    }
    if (dati.dettaglioCompenso && dati.dettaglioCompenso.value === "ral") {
      annuncio.ral = dati.stipendio;
      delete annuncio.dettaglioCompenso;
    }
    if (dati.dettaglioCompenso && dati.dettaglioCompenso.value === "netto") {
      annuncio.nettoMensile = dati.stipendio;
      delete annuncio.dettaglioCompenso;
    }
    if (dati.programmiRichiesti) {
      if (dati.programmiRichiesti.length > 0) {
        annuncio.programmiRichiesti = dati.programmiRichiesti.map((item) => item.label).join(",");
      }
    }

    annuncio.partitaIva = localStorage.getItem("partitaIvaJob");
    resolve(annuncio);
  });
}

export function convertCodificaMansioneToOptMansione(codifica) {
  return {
    value: codifica.tipologia + "_" + codifica.gruppo,
    label: codifica.descrizione,
    uuid: codifica.uuid,
    gruppo: codifica.gruppo,
  };
}

export function convertRetribuzioneSuppToOptSelect(retribuzione) {
  var arr = [];
  var splitRetr = retribuzione.split(",");
  options.retribuzione_supplementare.map((opt) => {
    if (splitRetr.includes(opt.value)) {
      arr.push(opt);
    }
  });
  return arr;
}

export function convertBenefitToOptSelect(benefit) {
  var arr = [];
  var splitBenefit = benefit.split(",");
  options.benefit.map((opt) => {
    if (splitBenefit.includes(opt.value)) {
      arr.push(opt);
    }
  });
  return arr;
}

export function convertOrariLavoroToOptSelect(orari) {
  var arr = [];
  var splitOrari = orari.split(",");
  options.turni_lavoro.map((opt) => {
    if (splitOrari.includes(opt.value)) {
      arr.push(opt);
    }
  });
  return arr;
}

export function convertLinguaToOptSelect(lingua) {
  var arr = {};
  options.lingue.map((opt) => {
    if (lingua === opt.value || lingua === opt.label) {
      arr = opt;
    }
  });
  return arr;
}

export function convertLivelloLinguaToOptSelect(livello) {
  var arr = {};
  options.livelli_lingue.map((opt) => {
    if (livello === opt.value || livello === opt.label) {
      arr = opt;
    }
  });
  return arr;
}

export function salvataggioAnnunci(
  datiAnnuncio,
  stepWithError,
  setStepWithError,
  stepCompleted,
  setStepCompleted,
  annunci,
  setAnnunci,
  setNewAnnuncio,
  newAnnuncio,
  setActiveStep,
  setDatiAnnuncio
) {
  var check = VerificaStep(datiAnnuncio, stepWithError, setStepWithError, stepCompleted, setStepCompleted);

  if (check) {
    mapObjToObjAnnuncio(datiAnnuncio)
      .then((res) => {
        var headers = getAuthHeader();
        headers["Content-Type"] = "application/json";
        fetch(process.env.REACT_APP_URL + "/aziende/salvaAnnuncioAzienda", {
          headers: headers,
          method: "POST",
          body: JSON.stringify(res),
        })
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            } else {
              JobErToast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio dell'annuncio" });
              return;
            }
          })
          .then((jsonText) => {
            if (jsonText) {
              var annunciCopy = annunci;
              annunciCopy.uuid = jsonText;

              var filterUuid = annunciCopy.dati.filter((obj) => obj.uuid === datiAnnuncio.uuid);

              var result = [];

              if (filterUuid.length > 0) {
                var filterUuid2 = annunciCopy.dati.filter((obj) => obj.uuid !== datiAnnuncio.uuid);
                filterUuid2.push(datiAnnuncio);
                result = [...filterUuid2];
              } else {
                datiAnnuncio.stato = 3;
                datiAnnuncio.uuid = jsonText;
                annunciCopy.dati.push(datiAnnuncio);
                result = annunciCopy.dati;
              }

              setAnnunci({ dati: result, loading: false });

              JobErToast.fire({ icon: "success", title: "Annuncio salvato con successo" });

              setNewAnnuncio(!newAnnuncio);
              setActiveStep(0);
              setDatiAnnuncio({
                dataPubblicazione: moment(new Date()).format("DD/MM/YYYY"),
              });
              setStepWithError([]);
              setStepCompleted([]);
            }
          });
      })
      .catch((err) => {
        console.error("Si è verificato un errore nel salvataggio dell'annuncio");
        JobErToast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio dell'annuncio" });
      });
  }
}
